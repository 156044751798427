import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Api } from '../../../libs/axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup
	.object({
		subject: yup.string().required('Subject is required!'),
		content: yup.string().min(5, 'Min character of Content is 5 character!').required('Content is required!'),
	})
	.required();
export const useTicket = () => {
	const [fetchTicketsLoading, setFetchTicketsLoading] = useState(true);
	const [addTicketModal, setAddTicketModal] = useState(false);
	const [tickets, setTickets] = useState({});
	const [postTicketLoading, setPostTicketLoading] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({ resolver: yupResolver(schema) });
	const onSubmit = async (body) => {
		try {
			setPostTicketLoading(true);
			await Api.post('/user/tickets', body);
			setPostTicketLoading(false);
			setAddTicketModal(false);
			reset();
			getTickets();
		} finally {
			setPostTicketLoading(false);
		}
	};
	const getTickets = async (page = 1) => {
		try {
			setFetchTicketsLoading(true);
			const { data } = await Api.get('/user/tickets');
			setTickets(data.data);
		} catch (e) {
			console.log('E', e);
		} finally {
			setFetchTicketsLoading(false);
		}
	};
	useEffect(() => {
		getTickets();
	}, []);
	const handlePageClick = (event) => {
		const newOffset = event.selected;
		getTickets(newOffset);
	};
	return {
		fetchTicketsLoading,
		addTicketModal,
		setAddTicketModal,
		tickets,
		onSubmit,
		handleSubmit,
		register,
		errors,
		postTicketLoading,
		reset,
		handlePageClick,
	};
};
