import { useEffect, useState } from "react";
import { useMainContext } from "../../../Context";
import { useQuery } from "../../../Hooks";
import { dashboard, maindata } from "../../../Utils/Apis";
import { Api } from "../../../libs/axios";

export const useDashboard = () => {
  const { post, dispatch } = useMainContext();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { searchParams, changeObj, getValue, createObjFilter } = useQuery({
    filters: [{ key: "chart_type" }],
  });
  const getDashboardData = async () => {
    setLoading(true);
    const { data, status } = await Api.get(dashboard, {
      ...createObjFilter(),
    });
    if (+status == 200) {
      console.log("dashboard data" , data)
      setData(data);
    }
    setLoading(false);
  };
  const getMainData = async () => {
    setLoading(true);
    const { data, status } = await post(maindata);
    if (+status == 200) {
      dispatch("maindata", data.setting);
    }
    setLoading(false);
  };
  useEffect(() => {
    getDashboardData();
    getMainData();
  }, [searchParams]);

  return { data, loading, changeObj, getValue };
};
