import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import OrderCard from '../../Components/Cards/Order';
import VipCard from '../../Components/Cards/Vip';
import { useMainContext } from '../../Context';
import { addMonth } from '../../Utils/functions';
import { useOrder } from './Hooks';
import './styles.scss';

const NewOrder = () => {
	const { form, setForm, toggle, submit, check, calcualte, modalToggler, data, loading, submitOrder, loadingBtn, paymentType, togglePaymentType } = useOrder();

	const {
		state: { plans, maindata, lang },
	} = useMainContext();

	const render = () => {
		if (form.vip) {
			return (
				<>
					<div className="vip-wrapper mt-18">
						{data?.map((data, key) => {
							return <VipCard loading={loading} key={key} data={data} form={form} setForm={setForm} />;
						})}
					</div>
					{!check('selected') && <p className="error-message mt-1">{lang == 'fa' ? <>لطفا یکی از پلن های بالا را انتخاب کنید</> : <>Please choose one of the plans above</>}</p>}
				</>
			);
		} else {
			return (
				<div className="d-flex justify-content-center">
					<OrderCard
						form={form}
						calcualte={calcualte}
						action={(price) => {
							setForm({ ...form, price });
						}}
						status={!check('price')}
						data={data}
					/>
				</div>
			);
		}
	};

	const CalcWithDrawTime = () => {
		var date = new Date();
		date.setDate(date.getDate() + 365);
		return date.toLocaleString('en-US', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});
	};

	return (
		<div className="new-order">
			<p className="title-order fs-12 fw-500 text-center">Invest in Cotex, and be the next to get rich.</p>

			<div className="d-flex align-items-center justify-content-center">
				<div className="toggle-component">
					<button className={form.vip && 'active'}>{lang == 'fa' ? <>سرمایه‌گذاری در پکیج‌های VIP</> : <>Invest in VIP packages</>}</button>
				</div>
			</div>
			{render()}

			<p className="point fs-14 fw-500 mt-18 mb-2">Investment Terms and Conditions:</p>

			<ol>
				<li className="fs-13 fw-500 mb-1">Your principal cannot be withdrawn until the end of the 365-day investment period.</li>
				<li className="fs-13 fw-400 mb-1">Profit from the investment can be withdrawn at any time.</li>
				<li className="fs-13 fw-400 mb-1">You can invest without any limitations on the amount or number of investments.</li>
				<li className="fs-13 fw-400 mb-1">Registration and processing of your investment may take up to 1 day.</li>
				<li className="fs-13 fw-400 mb-1">Please ensure that you verify the network and payment details carefully during the transaction process.</li>
			</ol>
			<div className="accept-roles">
				<div className={`role-check`}>
					<input
						type="checkbox"
						id="scales"
						name="scales"
						onChange={() => {
							setForm({ ...form, check: !form.check });
						}}
						checked={form.check}
					/>
					<label className={`fs-12 fw-500 ${!check('check') && 'error-message'}`} for="scales">
						I have read and accept the terms and conditions
					</label>
				</div>
				<button onClick={submit} disabled={+form.price < 30} className="md btn-fill" style={{ minWidth: 140 }}>
					Invest now
				</button>
			</div>
			<Modal show={form['ready']} onHide={modalToggler} backdrop="static" keyboard={false} centered>
				<div className="payment-modal">
					<div className="modal-title">
						{lang == 'fa' ? (
							<>
								<h4 className="fs-14 fw-600">پرداخت و سرمایه‌گذاری</h4>
							</>
						) : (
							<>
								<h4 className="fs-14 fw-600">Payment and investment</h4>
							</>
						)}
						<button onClick={modalToggler}>
							<img src={require('../../assets/images/close.png')} />
						</button>
					</div>
					<div className="payment-content">
						<div className="fs-15 mb-2">Order Details:</div>
						<div className="key-value">
							{lang == 'fa' ? (
								<>
									<span>مبلغ سرمایه‌گذاری</span>
								</>
							) : (
								<>
									<span>Investment amount</span>
								</>
							)}

							<span>{form.price ? form.price : calcualte()['min_amount']} $</span>
						</div>
						<div className="key-value">
							{lang == 'fa' ? (
								<>
									<span>سود پرداختی</span>
								</>
							) : (
								<>
									<span>Interest paid</span>
								</>
							)}
							<span>{calcualte()['interest_rates']} %</span>
						</div>
						<div className="key-value">
							<span>Time to withdraw</span>
							<span>{CalcWithDrawTime()}</span>
						</div>

						<div className="mt-4">
							<span className="fs-15">Payment Method:</span>
							<div className="d-flex align-items-center justify-content-center identification ">
								<div className="toggle-component">
									<button onClick={togglePaymentType} style={{ height: 48, minWidth: 160, fontSize: 14 }} className={paymentType === 'online' ? 'active text-text-3' : ''}>
										Online Payment
									</button>
									<button onClick={togglePaymentType} style={{ height: 48, minWidth: 160, fontSize: 14 }} className={paymentType === 'wallet' ? 'active text-text-3' : ''}>
										Wallet
									</button>
								</div>
							</div>
						</div>

						{paymentType === 'online' ? (
							<>
								<div className="key-value flex-column icon-checkbox ">
									<span className="mb-1">Token </span>
									<div className="mt-1">
										<img alt="TRX" src={require('../../assets/coins/TRX.png')} />
										<div>
											<span>TRX</span>
											<span>TRON</span>
										</div>
									</div>
								</div>
							</>
						) : (
							<>
								<p className="fs-15 text-center mt-4">Pay {form.price ? form.price : calcualte()['min_amount']} $ from your wallet.</p>
							</>
						)}

						<hr />
						<div className="key-value d-flex justify-content-center mb-2 mt-4">
							{lang == 'fa' ? (
								<>
									<span> برای پرداخت روی دکمه ثبت و تایید پرداخت کلیک کنید .</span>
								</>
							) : (
								<>
									<span>To pay, click on the registration and payment confirmation button.</span>
								</>
							)}
						</div>
						<div className="contact-support">
							<div className="d-flex justify-content-evenly">
								<button
									className="fs-10 fw-500 md btn-border w-100 mt-2"
									// href={"https://wa.me/" + maindata?.supportnumber}
									onClick={() => {
										submitOrder('coin');
									}}
									disabled={loadingBtn.coin}
								>
									{paymentType === 'wallet' ? <>Pay {form.price ? form.price : calcualte()['min_amount']} $ from your wallet</> : <>Registration and Payment (TRX)</>}
								</button>
							</div>
						</div>
					</div>
					<div></div>
				</div>
			</Modal>
		</div>
	);
};

export default NewOrder;
