import React from 'react';
import { useMainContext } from '../../../Context';
import Loading from '../../Loading';

const VipCard = ({ data, form, setForm, loading }) => {
	const isActive = form.selected == data.id;
	const {
		state: { lang, user },
	} = useMainContext();
	if (loading) {
		return <Loading col={2} height={'154px'} />;
	}

	// identification_required
	const isInValid = !user.user.is_identification_completed && data.identification_required;

	return (
		<div
			className={`vip-card  ${isActive ? 'vip-card-active' : ''} ${isInValid ? 'vip-card--disabled' : ''}`}
			onClick={() => {
				if (isInValid) return;
				setForm({
					...form,
					selected: isActive ? false : data.id,
				});
			}}
		>
			<h4 className="fs-32 fw-600 text-center">{data.title}</h4>

			<p className="fs-20 fw-600">{lang == 'fa' ? <>{` ${data.min_amount} دلار`}</> : <>{` $${data.min_amount}`}</>}</p>
			<p className="fs-12 fw-600">{lang == 'fa' ? `${`٪${data.interest_rates} سود`}` : `${`٪${data.interest_rates / 12} Profit per month`}   ${data?.id === 3 ? ' + 10,000 LRX token' : '' } `}</p>
			<p className="fs-12 fw-600">{lang == 'fa' ? `${`${data.period} روز`}` : `${`${data.period} days`}`}</p>
			{isInValid ? (
				<p className="" style={{ color: 'red', fontWeight: 500, fontSize: 12 }}>
					This packages requires identification. Please complete it first.
				</p>
			) : <></>}
		</div>
	);
};

export default VipCard;
