import { useEffect, useState } from 'react';
import { useMainContext } from '../../../Context';
import { listOrder, newOrder, packageList, withdrawStore } from '../../../Utils/Apis';
import { Api } from '../../../libs/axios';

export const useOrder = () => {
	const {
		post,
		state: { lang },
	} = useMainContext();
	const [form, setForm] = useState({});
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const get = async () => {
		setLoading(true);
		const { data } = await Api.get(listOrder);
		setData(data.data);
		setLoading(false);
	};
	useEffect(() => {
		get();
	}, []);
	const render = (item) => {
		switch (item.status) {
			case 'abandoned':
				return {
					text: lang == 'fa' ? 'پرداخت نشده' : 'Unpaid',
					access: [],
					status: 'error',
				};
			case 'pending':
				return {
					text: lang == 'fa' ? 'در حال بررسی پرداخت' : 'Checking Payment',
					access: ['details'],
					status: 'wait',
				};
			case 'active':
				// both
				return {
					text: lang == 'fa' ? 'سرمایه گذاری جاری' : 'Current Investment',
					access: ['details', 'request'],
					status: 'success',
				};
			case 'experid':
				// both
				return {
					text: lang == 'fa' ? 'تکمیل شده' : 'Completed',
					access: ['details', 'request'],
					status: 'success',
				};
			case 'errorpeyed':
				return {
					text: lang == 'fa' ? 'خطا در پرداخت' : 'Payment Error',
					access: [],
					status: 'error',
				};
				break;

			default:
				break;
		}
	};
	const renderDetails = (item) => {
		switch (item.status) {
			case 'pending':
				return {
					text: lang == 'fa' ? 'پرداخت نشده' : 'Unpaid',
					access: [],
					status: 'error',
				};
			case 'reject':
				return {
					text: lang == 'fa' ? 'رد شده' : 'Failed',
					access: ['details'],
					status: 'errors',
				};
			case 'payed':
				// both
				return {
					text: lang == 'fa' ? 'پرداخت شده' : 'Paid',
					access: ['details', 'request'],
					status: 'success',
				};
				break;
			case 'succses':
				// both
				return {
					text: lang == 'fa' ? 'موفق' : 'successful',
					access: ['details', 'request'],
					status: 'success',
				};

			default:
				return {
					text: lang == 'fa' ? 'پرداخت ناموفق' : 'Payment Failed',
					access: [],
					status: 'error',
				};
				break;
		}
	};

	return {
		loading,
		data,
		form,
		render,
		form,
		setForm,
		renderDetails,
	};
};
export const useRequest = ({ form, setForm }) => {
	const [loading, setLoading] = useState(false);
	const { post } = useMainContext();
	const sendRequest = async (data) => {
		setLoading(true);
		await post(withdrawStore, data, true);
		setForm({});
		setLoading(false);
	};
	return { loading, sendRequest };
};
