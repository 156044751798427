export const check = "/check";
export const register = "/register";
export const verified = "/verified";
export const validation = "/validation";
export const logout = "/logout";
export const dashboard = "/user/dashboard";
export const maindata = "/user/panel/data";
export const personal = "/user/profile/edit";
export const changepassword = "/user/change_password";
export const packageList = "/user/packages/list";
export const walletdata = "/user/wallet_data/edit";
export const newOrder = "/user/new/order";
export const listOrder = "/user/orders";
export const withdrawStore = "/user/withdraw/store";
