import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useMainContext } from '../../../Context';
import { changepassword, personal, walletdata } from '../../../Utils/Apis';
import { Api } from '../../../libs/axios';

export const usePersonal = () => {
	const {
		post,
		dispatch,
		state: { user },
	} = useMainContext();
	console.log(user);
	const [form, setForm] = useState({ fullname: user?.fullname });
	const [loading, setLoading] = useState(false);
	const setPersonal = async () => {
		setLoading(true);
		const { data, status } = await post(personal, form, true);
		if (+status == 200) {
			dispatch('user', { ...user, user: data });
		}
		setLoading(false);
	};
	return { setForm, form, user, loading, setPersonal };
};
export const usePassword = () => {
	const {
		post,
		dispatch,
		state: { user },
	} = useMainContext();
	console.log(user);
	const [form, setForm] = useState({
		oldpassword: null,
		newpassword: null,
		try_newpassword: null,
	});
	const [loading, setLoading] = useState(false);
	const setPassword = async () => {
		if (form.newpassword == form.try_newpassword) {
			setLoading(true);
			const { data, status } = await post(changepassword, form, true);
			if (+status == 200) {
				dispatch('user', { ...user, user: data });
			}
			setLoading(false);
		} else {
			toast.error('تکرار رمز عبور مطابقت ندارد');
		}
	};
	return { setForm, form, user, loading, setPassword };
};
export const useWalletData = () => {
	const {
		post,
		dispatch,
		state: { user },
	} = useMainContext();
	const [form, setForm] = useState({
		tranc_network: null,
		wallet_addres: null,
	});
	const [loading, setLoading] = useState(false);
	const setWallet = async () => {
		setLoading(true);
		const { data, status } = await post(walletdata, form, true);
		if (+status == 200) {
			dispatch('user', { ...user, user: { ...user.user, wallet: data } });
		}
		setLoading(false);
	};
	return { setForm, form, user, loading, setWallet };
};

export const useIdentification = () => {
	const [identificationType, setIdentificationType] = useState('IDCard'); // IDCard, Photo
	const [uploadLoading, setUploadLoading] = useState(false);
	const [files, setFiles] = useState({ front: null, back: null, photo: null });
	const [identificationStatus, setIdentificationStatus] = useState(true);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState({ front: false, back: false, photo: false });

	const { post } = useMainContext();

	const toggleIdentificationBtn = () => {
		setIdentificationType((pre) => (pre === 'IDCard' ? 'Photo' : 'IDCard'));
	};

	const uploadHandler = async (body) => {
		try {
			setUploadLoading(true);
			const { data, status } = await post('/user/identification', {
				// identify_type: identificationType, // IDCard, Photo
				// front: identificationType === 'IDCard' ? files.front : null,
				// back: identificationType === 'IDCard' ? files.back : null,
				// photo: identificationType === 'Photo' ? files.photo : null,
				...body,
			});
			if (status == 200) {
				window.location.reload();
			}
		} catch (e) {
			console.log('EE', e);
		} finally {
			setUploadLoading(false);
		}
	};
	const validationHandler = () => {
		if (identificationType === 'Photo') {
			if (files.photo) {
				uploadHandler({ identify_type: 'Photo', photo: files.photo });
			} else {
				setError({ photo: true });
			}
		} else {
			if (files.front && files.back) {
				uploadHandler({ identify_type: 'IDCard', front: files.front, back: files.back });
			} else {
				setError({ front: !files.front ? true : false, back: !files.back ? true : false });
			}
		}
	};
	useEffect(() => {
		const checkIdentification = async () => {
			setLoading(true);
			const { data } = await Api.get('/user/identification');
			setIdentificationStatus(data.data);
			setLoading(false);
		};
		checkIdentification();
	}, []);

	return {
		toggleIdentificationBtn,
		identificationType,
		uploadLoading,
		setFiles,
		files,
		identificationStatus,
		loading,
		validationHandler,
		error,
		setError,
	};
};
