import React from 'react';
import { useMainContext } from '../../../Context';

const OrderCard = ({ calcualte, action, status, form, data }) => {
	const {
		state: { lang },
	} = useMainContext();
	const renderError = () => {
		if (form.price) {
			if (data && data[0] && data[0].min_amount) {
				if (+form.price < +data[0].min_amount) {
					return <p className="error-message">The minimum investment amount is {+data[0].min_amount} USD</p>;
				}
			} else {
				if (+form.price < 30) {
					return <p className="error-message">The minimum investment amount is 30 USD</p>;
				}
			}
		}
		return '';
	};
	return (
		<div className="order-card">
			<h4 className="fs-12 fw-600">{lang == 'fa' ? 'مبلغ مورد نظر جهت سرمایه‌گذاری را وارد نمایید.' : 'Enter the desired investment amount.'}</h4>
			<div className="price-input" data-status={!status}>
				<span>$</span>
				<input
					placeholder="100"
					onChange={({ target: { value } }) => {
						action(value);
					}}
					autoFocus={true}
				/>
			</div>

			{renderError()}

			<div className="key-value mb-5 ">
				<p className="fs-12 fw-600">{lang == 'fa' ? 'مقدار سود' : 'Profit Amount'}</p>
				<p className="fs-12 fw-600">{calcualte()['interest_rates']}</p>
			</div>
			<div className="key-value">
				{lang == 'fa' ? <p className="fs-12 fw-600">مدت سرمایه‌گذاری</p> : <p className="fs-12 fw-600">Investment Period</p>}
				<p className="fs-12 fw-600">{calcualte()['period'] == '-' ? '-' : calcualte()['period'] + ' Days'}</p>
			</div>
		</div>
	);
};

export default OrderCard;
