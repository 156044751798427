import React from "react";
import Password from "./Components/Password";
import Personal from "./Components/Profile";
import Wallet from "./Components/Wallet";
import Identification from "./Components/Identification";
import "./styles.scss";

const Profile = () => {
  return (
    <>
      <div className="row">
        <Personal />
      </div>

      <div className="row">
        <Identification />
      </div>

      <div className="row">
        <Password />
        {/* <Wallet /> */}
      </div>
    </>
  );
};

export default Profile;
