import React from "react";
import SelectLanguage from "../Components/SelectLanguage";

const AuthLayout = ({ children }) => {
  return (
    <div className="auth-layout">
      {children}
      <div className="select-lang-wrapper">
        <SelectLanguage />
      </div>
    </div>
  );
};

export default AuthLayout;
