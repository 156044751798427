import React from 'react';
import { Spinner } from 'react-bootstrap';
import { usePersonal } from '../Hooks';
import { ReactComponent as Copy } from '../../../assets/images/copy.svg';
import { toast } from 'react-toastify';
import { useMainContext } from '../../../Context';
import Button from '../../../Components/button';

const Profile = () => {
	const { setForm, form, user, loading, setPersonal } = usePersonal();
	const {
		state: { lang },
	} = useMainContext();
	return (
		<div className="col-12">
			<div className="personal-box">
				{lang == 'fa' ? (
					<>
						<p className="fs-11 fw-500">
							شما می‌توانید اطلاعات حساب کاربری خود را ویرایش و یا مشاهده نمایید.
							<br />
							به دلایل امنیتی، شماره موبایل و ایمیل قابل تغییر نیستند. در صورت لزوم جهت تغییر این اطلاعات با پشتیبان در ارتباط باشید.
						</p>
					</>
				) : (
					<>
						<p className="fs-11 fw-500">
							You can edit or view your account information do it
							<br />
							For security reasons, mobile number and email cannot be changed. If necessary, contact support to change this information.
						</p>
					</>
				)}

				<div className="input">
					{lang == 'fa' ? (
						<>
							<label className="fs-12 fw-500 ml-2">نام و نام خانوادگی: </label>
						</>
					) : (
						<>
							<label className="fs-12 fw-500 ml-2">Fullname</label>
						</>
					)}
					<input onChange={(e) => setForm({ fullname: e.target.value })} defaultValue={user?.user?.fullname} />
					<img src={require('../../../assets/images/profile.png')} />
				</div>
				<div className="input-inline">
					<div className="input">
						{lang == 'fa' ? (
							<>
								<label className="fs-12 fw-500 ml-2">شماره تماس: </label>
							</>
						) : (
							<>
								<label className="fs-12 fw-500 ml-2">Phone number: </label>
							</>
						)}
						<input value={user?.user?.mobile} disabled />
						<img src={require('../../../assets/images/profile.png')} />
					</div>
					<div className="input">
						{lang == 'fa' ? (
							<>
								<label className="fs-12 fw-500 ml-2">ایمیل: </label>
							</>
						) : (
							<>
								<label className="fs-12 fw-500 ml-2">Email: </label>
							</>
						)}
						<input value={user?.user?.email} disabled />
						<img src={require('../../../assets/images/profile.png')} />
					</div>
				</div>
				{/* <div className="input invite-code">
          {lang == "fa" ? (
            <>
              <label className="fs-12 fw-500 ml-2">کد معرف : </label>
            </>
          ) : (
            <>
              <label className="fs-12 fw-500 ml-2">Identification code: </label>
            </>
          )}
          <input defaultValue={user?.user?.code} disabled={true} />
          <button
            onClick={() => {
              navigator.clipboard.writeText(user?.user?.code);
              toast.success(
                lang == "fa"
                  ? "کد معرف با موفقیت کپی شد"
                  : "ID code copied successfully"
              );
            }}
          >
            <Copy />
          </button>
        </div> */}

				<div className='mt-4'>
					<Button loading={loading} onClick={() => setPersonal()}>Save Changes</Button>
				</div>

				{/* <div className="btn-wrapper">
					<button onClick={() => setPersonal()}>{loading ? <Spinner animation="border" variant="light" size="sm" /> : lang == 'fa' ? 'دخیره تغییرات' : 'Save Changes'}</button>
				</div> */}
			</div>
		</div>
	);
};

export default Profile;
