import Layout from '../Layouts';
import AboutUs from '../Pages/AboutUs';
import TicketsListPage from '../Pages/Tickets/list';
import Dashboard from '../Pages/Dashboard';
import New from '../Pages/New';
import NotFound from '../Pages/NotFound';
import Orders from '../Pages/Orders';
import Profile from '../Pages/Profile';
import TicketSinglePage from '../Pages/Tickets/single';
import AuthLayout from '../Layouts/Auth';
import Auth from '../Pages/Auth';
import ReferralPage from '../Pages/referral';
import WalletPage from '../Pages/Wallet';

export const routes = [
	{
		path: '*',
		component: NotFound,
		layout: Layout,
	},
	{
		path: '/',
		component: Dashboard,
		layout: Layout,
	},
	{
		path: '/new-order',
		component: New,
		layout: Layout,
	},
	{
		path: '/tickets',
		component: TicketsListPage,
		layout: Layout,
	},

	{
		path: '/tickets/:id',
		component: TicketSinglePage,
		layout: Layout,
	},
	{
		path: '/referral',
		component: ReferralPage,
		layout: Layout,
	},

	{
		path: '/auth/login',
		component: Auth,
		layout: AuthLayout,
	},
	{
		path : "/wallet",
		component : WalletPage,
		layout: Layout,
	},

	// {
	//   layout: Layout,
	//   path: "/about-us",
	//   component: AboutUs,
	// },
	{
		layout: Layout,
		path: '/profile',
		component: Profile,
	},
	{
		layout: Layout,
		path: '/orders',
		component: Orders,
	},
];
