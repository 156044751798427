import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Api } from "../libs/axios";


export const useSearchObject = () => {
  
  const findNestedObj = (entireObj, keyToFind, valToFind) => {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind]) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  };
  return {
    findNestedObj,
  };
};

export const logOut = () => {
  const logout = async () => {
    const { data, status } = await Api.post("/logout");
    if (+status === 200) {
      localStorage.removeItem("user");
      window.location.replace("/");
    }
  };
  return { logout };
};
export const useCounter = ({ start, step = 0.01, plus, period }) => {
  const [counter, setCounter] = useState();
  useEffect(() => {
    setCounter(start);
  }, [start]);

  useEffect(() => {
    if (!counter) return;
    const timer =
      counter > 0 &&
      setInterval(
        () => setCounter(plus ? counter + step : counter - step),
        +period * 1000
      );
    return () => clearInterval(timer);
  }, [counter, start, period]);
  return [counter];
};
export const useQuery = ({ filters = [{ key: "page" }] }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const changeObj = (key, value) => {
    let obj = {};
    filters.map(element => {
      if (searchParams.get(element.key)) {
        obj = { ...obj, [element.key]: searchParams.get(element.key) };
      }
    });
    setSearchParams({ ...obj, [key]: value });
  };
  const createObjFilter = () => {
    let obj = {};
    filters.map(element => {
      if (searchParams.get(element.key)) {
        obj = { ...obj, [element.key]: searchParams.get(element.key) };
      }
    });
    return obj;
  };
  const getValue = key => {
    return key ? searchParams.get(key) || false : false;
  };
  const clear = () => {
    setSearchParams({});
  };
  return {
    createObjFilter,
    changeObj,
    searchParams,
    createObjFilter,
    getValue,
    clear,
  };
};
export const useMultiLanguage = ({ lan = "fa" }) => {};
