import { Spinner } from 'react-bootstrap';
import './styles.scss';

const Button = ({ children, size = 'md', className = '', loading = false, onClick = () => {}, type = 'button', styles = {}, spin = 'light', ...props }) => {
	return (
		<button type={type} onClick={onClick} disabled={loading} className={`cotex-btn size--${size} ${className} ${loading ? 'btn--loading' : ''}`} {...props}>
			<span className="cotext-btn--children">{children}</span>

			{loading && <Spinner animation="border" variant={spin} size="sm" />}
		</button>
	);
};

export default Button;
