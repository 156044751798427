const default_field_name = 'This field';
const attributes = {
	first_name: 'full name',
	last_name: 'family name',
	email: 'email',
	password: 'password',
	confirm_password: 'password confirmation',
	mobile: 'mobile',
	check_agreement: 'terms and conditions',
	user_name: 'username',
};

const messages = {
	required: '$attribute is required',
	minLength: '$attribute must be at least $min characters',
	maxLength: '$attribute must be at most $max characters',
	email: '$attribute is not valid',
	mobile: '$attribute is not valid and must starts with country code',
};

const Rules = {
	required(filedName) {
		return {
			value: true,
			message: messages.required.replace('$attribute', filedName || default_field_name),
		};
	},
	minLength(min, filedName) {
		return {
			value: min,
			message: messages.minLength.replace('$attribute', filedName || default_field_name).replace('$min', min.toString()),
		};
	},

	maxLength(max, filedName) {
		return {
			value: max,
			message: messages.maxLength.replace('$attribute', filedName || default_field_name).replace('$max', max.toString()),
		};
	},
	email() {
		return {
			pattern: {
				value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				message: messages.email.replace('$attribute', attributes.email),
			},
		};
	},

	mobile() {
		// /\b09\d{9}\b/,
		const regex = /[+]98\d{10}\b/;
		return {
			pattern: {
				value: regex,
				message: messages.mobile.replace('$attribute', attributes.mobile),
			},
		};
	},
};

export const Validator = (rules = []) => {
	let _result = {};
	rules.forEach((r) => {
		if (r.split(':')[1] && Object.keys(Rules).includes(r.split(':')[0])) {
			_result[r.split(':')[0]] = Rules[r.split(':')[0]](Number(r.split(':')[1]));
		} else {
			if (['mobile', 'email'].includes(r)) _result['pattern'] = Rules[r]().pattern;
			else _result[r] = Rules[r]();
		}
	});

	return _result;
};
