import axios from 'axios';
import { toast } from 'react-toastify';
import { API_SERVER } from '../Server';

export const Api = axios.create({
	// baseURL: 'https://api.cotex.io/api',
	baseURL: API_SERVER,
	timeout: 20000,
	withCredentials: true,
	headers: {
		ContentType: 'application/json',
		Accept: 'application/json',
	},
});

Api.interceptors.request.use(
	function (config) {
		let _user = localStorage.getItem('user');
		if (_user) {
			_user = JSON.parse(_user);
			config.headers.Authorization = `Bearer ${_user?.access_token}`;
		}
		return config;
	},
	function (error) {
		toast.error("An error occurred. Please try again later.");
		return Promise.reject(error);
	}
);

Api.interceptors.response.use(
	function (response) {
		console.log('axios response ::', response?.data?.success);
		if (response?.data?.success === false) {
			//* --------------------- Unsuccessful ---------------------------------
			let _msg = "An error occurred. Please try again later.";
			if (response?.data?.message && typeof response?.data?.message === 'string') _msg = response?.data?.message;
			toast.error(_msg);
			return Promise.reject(_msg);
		} else {
			//* -------------------- Successful ----------------------------------
			if (response?.data?.message && typeof response?.data?.message === 'string') {
				toast.success(response?.data?.message);
			}
			return response;
		}
	},
	function (error) {
		if (error?.response?.data?.alert && typeof error?.response?.data?.alert === 'string') {
			// show alert in component
		} else if (error?.response?.status == 422) {
			toast.error("Please check inputs and try again.");
		} else if (error?.response?.status == 401) {
			localStorage.removeItem('user');
			window.location.replace('/auth/login');
		} else if (error?.response?.status == 403) {
			// window.location.replace('/otp');
		} else if (error?.response?.data?.message && typeof error.response.data.message === 'string') {
			toast.error(error.response.data.message);
		} else if (typeof error?.response?.message === 'string') {
			toast.error(error.response.message);
		} else {
			toast.error("An error occurred. Please try again later.");
		}

		return Promise.reject(error);
	}
);
