import { useEffect, useState } from 'react';
import { useMainContext } from '../../Context';
import { toast } from 'react-toastify';

export const useReferral = () => {
	const {
		post,
		state: { user },
	} = useMainContext();
	const [fetchLoading, setFetchLoading] = useState(true);
	const [generateHandler, setGenerateHandler] = useState(false);

	//*
	const [referralCode, setReferralCode] = useState(''); //* From user Data
	const [customReferral, setCustomReferral] = useState(''); //* Custom Handwrite

	const [referrals, setReferrals] = useState({});

	const getReferralsList = async () => {
		try {
			setFetchLoading(true);
			const { data, status } = await post('/user/referral/list');
			if (+status === 200) {
				setReferrals(data);
			}
		} catch (e) {
			console.log('E', e);
		} finally {
			setFetchLoading(false);
		}
	};

	const generateReferralCodeHandler = async () => {
		try {
			const current_referral_code = user?.user?.referral_code;
			if (current_referral_code) {
				setReferralCode(current_referral_code);
				return;
			}

			const regex = /^[A-Za-z0-9_.]+$/;
			const FormData = {};

			if (!customReferral) {
				//* Auto Generate
				FormData.type = 'auto';
			} else {
				//* Manual Generate
				if (!regex.test(customReferral)) {
					toast.error('Invalid Referral Code, please use only a-z, A-Z, 0-9 characters');
					return;
				} else if(!customReferral || String(customReferral).length < 5) {
					toast.error('Invalid Referral Code, please use at least 5 characters');
					return;
				}
				FormData.type = 'manual';
				FormData.referral_code = customReferral;
			}

			setGenerateHandler(true);
			const { data, status, response } = await post('/user/referral/generate', FormData);
			if (+status === 200) {
				setReferralCode(data);
			}
		} catch (e) {
			console.log('E', e);
		} finally {
			setGenerateHandler(false);
		}
	};

	useEffect(() => {
		getReferralsList();
		const current_referral_code = user?.user?.referral_code;
		if (current_referral_code) {
			setReferralCode(current_referral_code);
		}
	}, [user]);

	return {
		referrals,
		fetchLoading,
		generateReferralCodeHandler,
		referralCode,
		user,
		customReferral,
		setCustomReferral,
		generateHandler,
	};
};
