import React from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useAuth } from './Hooks/useAuth';
import { ErrorMessage } from '@hookform/error-message';
import { Validator } from '../../providers/validator';
import { Shield, ShieldTick, UserTick } from 'iconsax-react';
import './styles.scss';

const Index = () => {
	const { loading, getOTPHandler, sendOTPForm, status, checkOTPHandler, checkOTPForm, username, changePage, resendLoading, resendOTPHandler } = useAuth();
	return (
		<div id="auth-page" className="auth-box">
			<div>
				<Link to={'/'} className="logo">
					{/* <img style={{ width: '100%' , height : "auto" }} src={require('../../assets/images/full-logo.png')} /> */}
					<p className='fw-500 text-text-1 mb-4 ' style={{ fontSize : 26 }}>Login/Register</p>
				</Link>
			</div>
			{status === 'otp' ? (
				<form onSubmit={sendOTPForm.handleSubmit(getOTPHandler)}>
					<div>
						<label className="fs-15 fw-500 text-center w-100">Please Enter your email address.</label>
						<div className="input-container">
							<div className={`input ${sendOTPForm.formState.errors.email ? 'input--error' : ''}`}>
								<input type="email" placeholder="Email address" {...sendOTPForm.register('email', Validator(['required', 'email']))} autoFocus className="text-left" />
							</div>
							<ErrorMessage errors={sendOTPForm.formState.errors} name="email" render={({ message }) => <p className="form-error">{message}</p>} />
						</div>
						<div className="btn-wrapper">
							<button disabled={loading} type="submit" className="md btn-border" style={{ height: 40 }}>
								{loading ? <Spinner animation="border" variant="light" size="sm" /> : <>Continue</>}
							</button>
						</div>
					</div>
				</form>
			) : (
				<>
					<form onSubmit={checkOTPForm.handleSubmit(checkOTPHandler)}>
						<div>
							{status === 'check' ? (
								<div>
									<label className="fs-14 fw-500 text-center w-100">
										<span>Please Enter The Code Received In Your Email.</span>
										<div className="mt-2 text-text-2 fs-12">{username}</div>
									</label>
									<div className="input-container">
										<div className={`input ${checkOTPForm.formState.errors.token ? 'input--error' : ''}`}>
				
											<input type="number" maxLength={6} placeholder="6 digits code" {...checkOTPForm.register('token', Validator(['required', 'minLength:6', 'maxLength:6']))} autoFocus className="text-left" />
										</div>
										<div className="ms-2">
											<button onClick={resendOTPHandler} type="button" style={{ width: 'auto', fontSize: 11 }} className="d-inline-block fw-500 text-sm mt-2 mb-2">
												{resendLoading ? <Spinner animation="border" variant="light" size="sm" /> : <>Resend code</>}
											</button>
										</div>
										<ErrorMessage errors={checkOTPForm.formState.errors} name="token" render={({ message }) => <p className="form-error">{message}</p>} />
									</div>

									<div>
										<input type="hidden" name="type" value={'otp'} {...checkOTPForm.register('type', Validator(['required']))} />
									</div>
								</div>
							) : (
								<div>
									<label className="fs-12 fw-500 text-center w-100">Please Enter Your Password.</label>
									<div className="input-container">
										<div className={`input ${checkOTPForm.formState.errors.token ? 'input--error' : ''}`}>
									
											<input type="password" placeholder="password" {...checkOTPForm.register('token', Validator(['required', 'minLength:6', 'maxLength:32']))} className="text-left" />
										</div>

										<ErrorMessage errors={checkOTPForm.formState.errors} name="token" render={({ message }) => <p className="form-error">{message}</p>} />
									</div>

									<div>
										<input type="hidden" name="type" value={'password'} {...checkOTPForm.register('type', Validator(['required']))} />
									</div>
								</div>
							)}

							<div className="btn-wrapper">
								<button disabled={resendLoading || loading} type="submit" className="fw-500 md btn-border" style={{ height: 40 }}>
									{loading ? <Spinner animation="border" variant="light" size="sm" /> : <>Enter</>}
								</button>
							</div>
							{status === 'password' && (
								<div className="other-btn-container">
									
									<button type="button" disabled={resendLoading} style={{ width: 'auto', fontSize: 11 }} onClick={() => changePage('check')}>
										{resendLoading ? <Spinner animation="border" variant="light" size="sm" />  : <span>Continue with OTP</span>}
									</button>
								</div>
							)}
						</div>
					</form>
				</>
			)}
		</div>
	);
};

export default Index;
