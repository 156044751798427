import { Modal } from 'react-bootstrap';
import './styles.scss';
import { useWallet } from './useWallet';
import Button from '../../Components/button';
import { ArrowDown, ArrowDown2, ArrowUp } from 'iconsax-react';
import Loading from '../../Components/Loading';
import moment from 'jalali-moment';

const WalletPage = () => {
	const {
		depositModal,
		setDepositModal,
		depositType,
		setDepositType,
		submitLoading,
		setWithdrawModal,
		withdrawModal,
		wallet,
		loading,
		transactionLoading,
		walletTransactions,
		setWithdrawAmount,
		withdrawAmount,
		withdrawHandler,
		depositAmount,
		depositHandler,
		setDepositAmount,
		setVoucherCode,
		voucherCode,
	} = useWallet();

	if (loading) {
		return <Loading />;
	}

	return (
		<div id="wallet-page">
			<div className="text-center mt-4">
				<h1 className="d-flex align-items-center justify-content-center gap-2 flex-wrap">
					<span className="fw-700" style={{ fontSize: 59 }}>
						{wallet?.balance !== undefined ? Number(wallet?.balance)?.toFixed(2) : '--'}
					</span>
					<span className="fs-13">USD</span>
				</h1>

				<h3 className="fs-20 fw-normal">Total Balance</h3>

				<p className="fw-300 fs-12">Freezed amount: {wallet?.freeze_amount ? Number(wallet?.freeze_amount)?.toFixed(2) : '--'} USD </p>

				<div className="d-flex align-items-center justify-content-center fex-wrap gap-3 mt-4 actions-btn-wallet ">
					<button className="withdraw-btn" onClick={() => setWithdrawModal(true)}>
						Withdraw
					</button>
					<button className="bg-text-1  " onClick={() => setDepositModal(true)}>
						<span className="text-text-3">Top up</span>
						<ArrowUp color="#000" size={17} />
					</button>
				</div>
			</div>

			<div className="">
				<div style={{ borderRadius: 6 }}>
					<h2 className=" mt-5 mb-2 fs-16">Wallet Transactions</h2>
					<div className="table-content wallet-table">
						<table className="table ">
							<thead>
								<tr>
									<th>#</th>
									<th>Date</th>
									<th>Amount</th>
									<th>Transaction Type</th>
									<th>Status</th>
								</tr>
							</thead>

							<tbody>
								{walletTransactions?.data?.map((item, key) => (
									<tr key={key}>
										<td width={60}>{key + 1}</td>
										<td width={150}>{moment(item.created_at).format('YYYY/MM/DD')}</td>
										<td className={item.status !== 'confirmed' ? 'text-text-1' : item.type === 'withdraw' ? 'text-danger' : 'text-success'}>
											{item.type === 'withdraw' ? '-' : '+'}
											{item.amount}
										</td>
										<td>{item.type}</td>
										<td className={'wallet-status'}>
											<span className={'wallet-status--' + item.status}></span>
											{item.status}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					{/* <Pagination /> */}
				</div>
			</div>

			<Modal show={depositModal} onHide={() => setDepositModal(false)} centered>
				<Modal.Body>
					<div className="withdraw-modal">
						<h4 className="mb-3 " style={{ fontSize: 17 }}>
							Deposit
						</h4>
						<div className="d-flex align-items-center justify-content-center ">
							<div className="toggle-component">
								<button onClick={() => setDepositType('voucher')} className={depositType === 'voucher' ? 'active' : ''}>
									Voucher code
								</button>
								<button onClick={() => setDepositType('online')} className={depositType === 'online' ? 'active' : ''}>
									Online Payment
								</button>
							</div>
						</div>

						<ul style={{ listStyleType: 'square' }}>
							<li>
								<p className="fs-13 fw-300">Minimum deposit amount: 10 USD</p>
							</li>

							{depositType === 'online' && (
								<li>
									<p className="fs-13 fw-300">Confirmation and processing of your online may take up to 3 hours.</p>
								</li>
							)}
						</ul>
						<div className="mt-3">
							{depositType === 'online' && (
								<>
									<div className="input">
										<input type="number" placeholder="Amount in USD" value={depositAmount} onChange={(e) => setDepositAmount(e.target.value)} />
									</div>
								</>
							)}

							{depositType === 'voucher' && (
								<>
									<div className="input">
										<input type="text" placeholder="Enter voucher code" value={voucherCode} onChange={(e) => setVoucherCode(e.target.value)} />
									</div>
								</>
							)}

							<Button className="bg-text-1 text-text-3 w-100 submit-btn  mt-3" loading={submitLoading} spin={'dark'} onClick={depositHandler}>
								<span className="text-text-3 d-inline-block  ">Submit</span>
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={withdrawModal} onHide={() => setWithdrawModal(false)} centered>
				<Modal.Body>
					<div className="withdraw-modal">
						<h4 className="mb-3 " style={{ fontSize: 17 }}>
							Withdraw
						</h4>

						<ul style={{ listStyleType: 'square' }}>
							<li>
								<p className="fs-12 fw-300" style={{ lineHeight: 2 }}>
									Please enter the amount you wish to withdraw. After submitting your request, it will be sent to our team for approval. Once approved, the requested amount will be transferred to your account.
									Please ensure that all the information you enter is correct before proceeding.
								</p>
							</li>
							<li>
								<p className="fs-13 fw-300">Minimum withdraw amount: 50 USD</p>
							</li>
						</ul>

						<div className="mt-3">
							<div className="input">
								<input type="number" placeholder="Amount in USD" value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} />
								<button
									onClick={() => {
										if (Number(wallet?.balance) > 0) {
											setWithdrawAmount(wallet?.balance);
										}
									}}
								>
									Max
								</button>
							</div>

							{Number(wallet?.balance) <= 0 && <p className="fs-13 text-danger mt-1 fw-500">Insufficient balance</p>}
							<Button onClick={withdrawHandler} disabled={Number(wallet?.balance) <= 0} className="bg-text-1 text-text-3 w-100 submit-btn  mt-3" loading={submitLoading} spin={'dark'}>
								<span className="text-text-3 d-inline-block  ">Submit</span>
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default WalletPage;
