import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { routes } from './Router';
import AuthLayout from './Layouts/Auth';
import Auth from './Pages/Auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { caching } from './Utils/functions';
import { useMainContext } from './Context';
import { Modal, Spinner } from 'react-bootstrap';
import { Api } from './libs/axios';

const App = () => {
	const { state } = useMainContext();

	// const [authStatus, setAuthStatus] = useState('loading'); //* loading, success, error

	// const getUser = async () => {
	// 	try {
	// 		let user_data = localStorage.getItem('user');
	// 		if (!state?.user?.access_token || !user_data) {
	// 			setAuthStatus('error');
	// 			return;
	// 		}
	// 		//* Update User data by each reload page or every time visit the panel.
	// 		const { data } = await Api.get('/user/profile');

	// 		user_data = JSON.parse(user_data);
	// 		user_data = { ...user_data, user: data?.data };
	// 		localStorage.setItem('user', JSON.stringify(user_data));
	// 		setAuthStatus('success');
	// 	} catch (e) {
	// 		console.log('E', e);
	// 		localStorage.removeItem('user');
	// 		setAuthStatus('error');
	// 	} finally {
	// 		//
	// 	}
	// };

	// useEffect(() => {
	// 	// getUser();
	// }, [state.user]);

	useEffect(() => {
		caching();
	}, []);

	useEffect(() => {
		document.body.classList.add('en');
		document.body.setAttribute('dir', 'ltr');

		return () => {
			// document.body.classList.remove("en");
		};
	}, []);

	return (
		<Routes>
			{routes.map((route, count) => {
				return (
					<Route
						key={count}
						path={route.path}
						element={
							<route.layout>
								<route.component />
							</route.layout>
						}
					/>
				);
			})}
		</Routes>
	);

	// if (!state?.user?.access_token) {
	// 	<Navigate to={'/auth/login'} />;
	// }

	// return (
	// 	<>
	// 		{authStatus === 'loading' ? (
	// 			<Modal show centered>
	// 				<Modal.Body>
	// 					<div className="d-flex align-items-center justify-content-center flex-column">
	// 						<img src={require('./assets/icons/RANGI.png')} alt="logo" style={{ width: 120, height: 120 }} />
	// 						<div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
	// 							<Spinner size="sm" />
	// 							<p>Please wait...</p>
	// 						</div>
	// 					</div>
	// 				</Modal.Body>
	// 			</Modal>
	// 		) : authStatus === 'error' ? (
	// 			<AuthLayout children={<Auth />} />
	// 		) : (

	// 		)}
	// 	</>
	// );

	// if (state?.user?.access_token && state?.user?.user?.role !== 'user') {
	// 	return (
	// 		<>

	// 		</>
	// 	);

	// 	return (

	// 	);
	// } else {
	// 	return <AuthLayout children={<Auth />} />;
	// }
};

export default App;
