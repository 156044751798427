import { useState } from 'react';
import { useMainContext } from '../../../Context';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useAuth = () => {
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState('');
	const [status, setStatus] = useState('otp'); // "otp" , "check" ,  "password"
	const [resendLoading, setResendLoading] = useState(false);
	const navigate = useNavigate();
	const { post, dispatch } = useMainContext();
	const [searchParams] = useSearchParams();

	const sendOTPForm = useForm();
	const checkOTPForm = useForm();

	const getOTPHandler = async (formData) => {
		try {
			setLoading(true);
			const { data, status, response } = await post('/auth/attempt', formData, true);
			if (status === 200) {
				console.log('redirecting to ', response?.data?.redirect);
				setUsername(formData.email);
				if (response?.data?.redirect === 'otp') setStatus('check');
				else if (response?.data?.redirect === 'password') setStatus('password');
			}
		} catch (e) {
			console.log('E', e);
		} finally {
			setLoading(false);
		}
	};

	const checkOTPHandler = async (formData) => {
		try {
			console.log('forformData', formData);
			setLoading(true);
			// const _ref_code = searchParams.get('ref_code') ? String(searchParams.get('ref_code')).trim() : '';
			let _ref_code = findReferral();
			const res = await post('/auth/check', { ...formData, ref_code: _ref_code, email: username, type: status === 'check' ? 'otp' : 'password' }, true);
			if (res?.status === 200) {
				dispatch('user', res?.response?.data);
				const _redirect_string = searchParams.get('redirect') ? String(searchParams.get('redirect')).trim() : '';
				if (_redirect_string && !_redirect_string.includes('login')) {
					window.location.replace(_redirect_string);
				} else {
					window.location.replace('/new-order');
				}
			}
		} catch (e) {
			console.log('E', e);
		} finally {
			setLoading(false);
		}
	};

	const findReferral = () => {
		try {
			let _ref_code = '';
			const href = window.location.href;
			if (href) {
				_ref_code = href.split('?ref_code=')[1];
				return _ref_code;
			}
			return _ref_code;
		} catch (error) {
			return '';
		}
	};

	const resendOTPHandler = async () => {
		try {
			setResendLoading(true);
			//resend-otp
			const { data, status, response } = await post('/auth/resend-otp', { email: username }, true);
		} catch (e) {
			console.log('E', e);
		} finally {
			setResendLoading(false);
		}
	};

	const changePage = async (to) => {
		// Step-1: Call Resend
		await resendOTPHandler();
		// Step-2: Change Page.
		if (to === 'check' && status === 'password') {
			setStatus('check');
		}
	};

	return {
		loading,
		getOTPHandler,
		sendOTPForm,
		status,
		checkOTPHandler,
		checkOTPForm,
		username,
		changePage,
		resendOTPHandler,
		resendLoading,
	};
};
