import React from "react";
import { Dropdown } from "react-bootstrap";
import { useMainContext } from "../../Context";

const SelectLanguage = () => {
  const { state, dispatch } = useMainContext();
  return (
    <>
      {/* <Dropdown className="select-language">
        <Dropdown.Toggle id="dropdown-basic">
          {state.lang == "fa" ? (
            <>
              <span>Persian</span>
              <img src={require("../../assets/images/fa.png")} />
            </>
          ) : (
            <>
              <span>English</span>
              <img src={require("../../assets/images/en.png")} />
            </>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              dispatch("lang", state.lang == "fa" ? "en" : "fa");
            }}
          >
            {state.lang == "en" ? (
              <>
                <span>Persian</span>
                <img src={require("../../assets/images/fa.png")} />
              </>
            ) : (
              <>
                <span>English</span>
                <img src={require("../../assets/images/en.png")} />
              </>
            )}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
    </>
  );
};

export default SelectLanguage;
