import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useMainContext } from '../../../Context';
import { usePassword } from '../Hooks';
import Button from '../../../Components/button';

const Password = () => {
	const { setForm, form, user, loading, setPassword } = usePassword();
	const {
		state: { lang },
	} = useMainContext();
	return (
		<div className="col-12">
			<div className="personal-box">
				<div className="icon-wrapper">{/* <img src={require("../../../assets/images/lock.png")} /> */}</div>
		

				<div className="input mb-10">
					{lang == 'fa' ? <label className="fs-12 fw-500">گذرواژه قبلی: </label> : <label className="fs-12 fw-500">Previous Password: </label>}
					<input
						type={'password'}
						onChange={(e) => {
							setForm({ ...form, oldpassword: e.target.value });
						}}
					/>
				</div>

        <p className='fs-10'>* If you have not set your password before, leave this field blank.</p>

				<div className="input mb-10">
					{lang == 'fa' ? <label className="fs-12 fw-500">گذرواژه جدید: </label> : <label className="fs-12 fw-500">New Password: </label>}
					<input
						type={'password'}
						onChange={(e) => {
							setForm({ ...form, newpassword: e.target.value });
						}}
					/>
				</div>
				<div className="input mb-10">
					{lang == 'fa' ? <label className="fs-12 fw-500">گذرواژه جدید: </label> : <label className="fs-12 fw-500">New Password: </label>}
					<input
						type={'password'}
						onChange={(e) => {
							setForm({ ...form, try_newpassword: e.target.value });
						}}
					/>
				</div>
				<div className="mt-3">
					<Button loading={loading} onClick={() => setPassword()}>
						Change Password
					</Button>
					{/* <button onClick={() => setPassword()}>
            {loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : lang == "fa" ? (
              "تغییر گذرواژه"
            ) : (
              "Change Password"
            )}
          </button> */}
				</div>
			</div>
		</div>
	);
};

export default Password;
