import React, { useEffect, useRef, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);




const options = {
	fill: true,
	tension: 0.4,
	// responsive: true,
	maintainAspectRatio: false,
	scales: {
		x: {
			// type: this.xType,
			// ticks: {
			//     callback: function (label) {
			//         return label / 1000 + 'k';
			//     }
			// },
			// stacked: this.forceStacked, // for line chart better never to stack data
			display: true,
			border: {
				display: true,
				color: "#999",
				padding: 10,
				width: 1,
				// dashOffset: 30
			},
			grid: {
				display: true,
				// circular: true,
				tickBorderDash: [2, 3],
				lineWidth: 1,
				offset: true,
				tickBorderDashOffset: 5,
				tickColor: "#ccc",
				tickWidth: 1,
				tickLength: 30
				// drawOnChartArea : false
			},

			// title: {
			// 	display: true,
			// 	text: "x title",
			// 	padding: 10,
			// 	color: "#2c3e5",
			// 	font: {
			// 		size: 30
			// 	}
			// },
			ticks: {
				// backdropPadding: 50,
				color: '#777',

				font: {
					size: 12
				}
			}
		},
		y: {
			// stacked: this.forceStacked,  // for line chart better never to stack data
			display: true,
			border: {
				display: true,
				color: "#999",
				width: 1,
				// dashOffset: 30
			},
			title: {
				display: false,
				text: "y title",
				padding: 10,
				color: "#2c3e5",
			},
			ticks: {
				// backdropPadding: 50,
				color: '#777',
				// callback: (label) => this.formatXAxis(label),
				// callback: function (label) {
				//     return this.formatNumber(label)
				//     // return label / 1000 + 'k';
				// },
				font: {
					size: 12
				}
			},
			suggestedMin:  false,
			suggestedMax:  false
		}
	},
	elements: {
		point: {
			radius: 2
		}
	},

	// elements: {
	// 	point: {
	// 		borderWidth: 0,
	// 		// radius: 0,
	// 		backgroundColor: 'rgba(0,0,0,0)',
	// 	},
	// },
	interaction: {
		intersect: false,
	},

	// scales: {
	// 	y: {
	// 		min: 0,
	// 		ticks: {
	// 			font: { family: 'Poppins', size: '12' },
	// 			stepSize: 100,
	// 		},
	// 		position: 'right',
	// 	},
	// 	x: {
	// 		grid: {
	// 			display: false,
	// 		},
	// 		ticks: {
	// 			font: { family: 'Poppins', size: '12' },
	// 		},
	// 	},
	// },
	plugins: {
		title: {
			display: true,
			text: "Investments Profits"
		},
		legend: {
			display: false,
		},
		tooltip: {
			enabled: true,
			mode: 'index',
			padding: {
				left: 25,
				right: 25,
				top: 15,
				bottom: 15
			},
			caretPadding: 10,
			cornerRadius: 12,
			boxWidth: 20,
			boxPadding: 6,
			borderColor: "rgba(0,0,0,0)",
			titleMarginBottom: 10,
			titleFont: {
				size: 14
			}
		},
	},
	pointDot: false,
};

export default function LineChart({ dataSets, getValue, changeObj }) {
	const [state, setState] = useState({ datasets: [] });


	const [chartSeries , setChartSeries] = useState([]);
	const [chartOptions , setChartOptions] = useState({});

	const chartRef = useRef(null);
	useEffect(() => {

	

		if(!dataSets) return;
		console.log("ser ser"  , dataSets);
		let _chart_series = [];


		_chart_series.push({
			name : "Total Investment",
			data : dataSets?.map((dd) => dd.total)
		});
		_chart_series.push({
			name : "Profit",
			data : dataSets?.map((dd) => dd.value)
		});
		setChartSeries(_chart_series);
	




		const chart = chartRef.current;
		if (!chart) return;

		const gradient = chart.ctx.createLinearGradient(0, 0, 0, 900);
		gradient.addColorStop(0, 'rgba(76, 114, 172, 0.33)');
		gradient.addColorStop(0.5, 'rgba(191, 215, 250, 0.13)');
		gradient.addColorStop(1, '#fff');
		setState({
			labels: dataSets?.map((dd) => dd.label),
			datasets: [
				{
					label: '$',
					data: dataSets?.map((dd) => dd.total),
					backgroundColor: gradient,
					borderWidth: '3',
					borderColor: 'rgba(76, 114, 172, 1)',
				},
			],
		});

	
		// setChartSeries()

	}, [dataSets]);





	return (
		<div className="plot">
			{dataSets?.length === 0 && (
				<div className="overlay flex-column gap-2 px-2">
					<p style={{ maxWidth: 450, lineHeight: 1.9, textAlign: 'center' }} className="fs-14 ">
						You haven't started investing yet.
						<br />
						Submit your first Investment right now
					</p>
					<Link to={'/new-order'} className="fs-19 btn fw-500 mt-2">
						Get Started
					</Link>
				</div>
			)}

			<div className="title-plot">
				<div>
					<h4 className="fs-12 fw-600">Trunover 2024</h4>
				</div>

				<div className="d-flex align-items-center justify-content-center">
					<div className="toggle-component">
						<button style={{ height: 28, fontSize: 12, padding: '5px 20px' }} className={'active'}>
							Daily
						</button>
					</div>
				</div>
			</div>
			{/* <ReactApexChart options={DEFAULT_CHART_OPTIONS} series={chartSeries} type="area" height={350} /> */}

			<Line data={state} ref={chartRef} options={options} height={550} />
		</div>
	);
}
