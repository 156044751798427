import { useEffect, useState } from 'react';

import { Api } from '../../libs/axios';
import { toast } from 'react-toastify';

export const useWallet = () => {
	const [depositModal, setDepositModal] = useState(false);
	const [withdrawModal, setWithdrawModal] = useState(false);

	const [depositType, setDepositType] = useState('online');

	const [submitLoading, setSubmitLoading] = useState(false);

	const [loading, setLoading] = useState(false);
	const [transactionLoading, setTransactionLoading] = useState(false);

	const [walletTransactions, setWalletTransactions] = useState({});

	const [wallet, setWallet] = useState(0);

	const [withdrawAmount, setWithdrawAmount] = useState();

	const [depositAmount, setDepositAmount] = useState();
	const [voucherCode, setVoucherCode] = useState('');

	const getWallet = async () => {
		try {
			setLoading(true);
			const { data } = await Api.get('/user/wallet/get');
			setWallet(data?.data || {});
		} catch (e) {
			console.log('E', e);
		} finally {
			setLoading(false);
		}
	};

	const getTransactions = async () => {
		try {
			setTransactionLoading(true);
			const { data } = await Api.get('/user/wallet/transactions');
			setWalletTransactions(data?.data || {});
		} catch (e) {
			console.log('E', e);
		} finally {
			setTransactionLoading(false);
		}
	};

	const withdrawHandler = async () => {
		try {
			if (withdrawAmount < 50) {
				toast.error('Minimum withdraw amount is 50 USD');
				return;
			}

			setSubmitLoading(true);
			const { data } = await Api.post('/user/wallet/withdraw', {
				amount: withdrawAmount,
			});
			setWithdrawModal(false);
			await getWallet();
			await getTransactions();
		} catch (e) {
			console.log('E', e);
		} finally {
			setSubmitLoading(false);
		}
	};

	const depositHandler = async () => {
		try {
			setSubmitLoading(true);
			const { data } = await Api.post('/user/wallet/deposit', {
				amount: depositType === 'online' ? depositAmount : null,
				voucher: depositType === 'voucher' ? voucherCode : null,
				type: depositType,
			});
            if(depositType === 'online') {
                console.log('DATA', data?.invoice_url);
                window.location.replace(data?.invoice_url);
            } else {
                setDepositModal(false);
                await getWallet();
                await getTransactions();
                setSubmitLoading(false);
            }
	
			// setDepositModal(false);
		} catch (e) {
			console.log('E', e);
            setSubmitLoading(false);
		} finally {
            //
		}
	};

	useEffect(() => {
		getWallet();
		getTransactions();
	}, []);

	return {
		depositModal,
		depositType,
		setDepositModal,
		setDepositType,
		submitLoading,
		withdrawModal,
		setWithdrawModal,
		wallet,
		loading,
		walletTransactions,
		transactionLoading,
		withdrawAmount,
		setWithdrawAmount,
		withdrawHandler,
		depositAmount,
		setDepositAmount,
		depositHandler,
		setVoucherCode,
		voucherCode,
	};
};
