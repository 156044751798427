import { Badge } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Button from '../../../Components/button';
import './styles.scss';
import { useEffect, useRef, useState } from 'react';
import { Api } from '../../../libs/axios';
import Loading from '../../../Components/Loading';
import moment from 'jalali-moment';

const TicketSinglePage = () => {
	const params = useParams();
	const messagesEndRef = useRef(null);
	const [message, setMessage] = useState('');
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);

	const { id } = useParams();

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			const { data } = await Api.get(`/user/tickets/${id}`);
			setData(data.data);
			setLoading(false);
		};
		getData();
	}, []);
	console.log({ data });
	useEffect(() => {
		const scrollToBottom = () => {
			if (messagesEndRef.current) {
				messagesEndRef.current.scrollIntoView();
			}
		};
		scrollToBottom();
	}, [messagesEndRef]);
	const send = async () => {
		if (message) {
			setLoadingBtn(true);
			const response = await Api.post(`/user/tickets/${id}`, {
				content: message,
			});
			setData({ ...data, messages: { ...data.messages, data: [...data.messages.data, response.data.data] } });
			setMessage('');
			setLoadingBtn(false);
		}
		setLoadingBtn(false);
	};
	if (loading) {
		return <Loading count={3} />;
	}
	return (
		<div className="single-ticket-page ">
			<div className="ticket-card">
				<div className="d-flex justify-content-between flex-wrap align-items-center pb-2 mb-2 mt-2 border-bottom">
					<h2 className="">
						<span>{data.ticket.subject}</span>
						{/* <Badge className="ms-2">{data.ticket.status}</Badge> */}
					</h2>
					<span>#{params.id}</span>
				</div>

				<div className="chat-container">
					{data.messages.data.map((message) => (
						<>
							<div className={`chat-message ${message.authorable_type === 'user' && 'owner'}`}>
								<p>{message.body}</p>
								<div className="chat-message--date">{moment(message.created_at).format('YYYY/MM/DD hh:mm')}</div>
							</div>
						</>
					))}
					<div ref={messagesEndRef} />
				</div>
				<div className="input-container">
					<input
						disabled={loadingBtn}
						type="text"
						placeholder="Message"
						value={message}
						onChange={({ target: { value } }) => setMessage(value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								send();
							}
						}}
					/>
					<Button className="btn-fill" onClick={send} loading={loadingBtn} styles={{ height: 60 }}>
						<span className="fs-19">Send</span>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default TicketSinglePage;
