import React, { useEffect, useState } from 'react';
import { Modal, Offcanvas, Spinner } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Income from '../Components/Cards/Income';
import SupportCard from '../Components/Cards/Support';
import Loading from '../Components/Loading';
import { useMainContext } from '../Context';
import { now, nowEn } from '../Utils/functions';
import { useMainData } from './Hooks';
import { Api } from '../libs/axios';
import { HambergerMenu, Home, I24Support, Money, Receipt, Setting2, UsdCoin, UserAdd, Wallet, Wallet1 } from 'iconsax-react';
import './styles.scss';

const Layout = ({ children }) => {
	const [show, setShow] = useState(false);
	const [left, setLeft] = useState(false);
	const { logout, state, dispatch } = useMainContext();
	const toggle = () => setShow(!show);
	const { pathname  } = useLocation();
	const { data, loading } = useMainData();

	useEffect(() => {
		if (!show) return;
		setShow(false);
	}, [pathname]);

	let links = [
		{
			label: 'Home Page',
			to: '/',
			img: <Home />,
			title: 'داشبورد',
			fa: {
				label: 'صفحه اصلی',
				title: 'داشبورد',
			},
			en: {
				label: 'Home Page',
				title: 'Dashboard',
			},
		},
		{
			label: 'New Investment',
			to: '/new-order',
			title: 'سرمایه‌گذاری جدید',
			img: <UsdCoin />,
			fa: {
				label: 'سرمایه گذاری جدید',
				title: 'سرمایه‌گذاری جدید',
			},
			en: {
				label: 'New Investment',
				title: 'New Investment',
			},
		},

		{
			label: 'Investment History',
			to: '/orders',
			img: <Receipt />,
			title: 'سرمایه‌گذاری‌های پیشین',
			fa: {
				label: 'سرمایه گذاری پیشین',
				title: 'سرمایه‌گذاری‌های پیشین',
			},
			en: {
				label: 'Investment History',
				title: 'Investment History',
			},
		},
		{
			label: 'User Setting',
			to: '/profile',
			img: <Setting2 />,
			title: 'حساب کاربری',
			fa: {
				label: 'صفحه اصلی',
				title: 'داشبورد',
			},
			en: {
				label: 'User Setting',
				title: 'User Setting',
			},
		},

		{
			label: 'Referral Code',
			to: '/referral',
			img: <UserAdd />,
			title: 'Referral Code',
			fa: {
				label: '',
				title: '',
			},
			en: {
				label: 'Referral',
				title: 'Referral',
			},
		},

		{
			label: 'My Wallet',
			to: '/wallet',
			img: <Wallet />,
			title: 'My Wallet',
			fa: {
				label: '',
				title: '',
			},
			en: {
				label: 'Wallet',
				title: 'Wallet',
			},
		},
		{
			label: 'Support',
			to: '/tickets',
			title: '',
			img: <I24Support />,
			fa: {
				label: '',
				title: '',
			},
			en: {
				label: 'Support',
				title: 'Support',
			},
		},
	];
	const render = () => {
		let res = '';
		links.map((link) => {
			if (link.to == pathname) {
				res = state.lang == 'fa' ? link.title : link.en.title;
			}
		});
		return res;
	};
	const renderLastOrder = () => {
		if (loading) {
			return <Loading />;
		} else {
			if (data?.orders?.length != 0) {
				return data?.orders?.map((order) => {
					return <Income type="progress" data={order} />;
				});
			} else {
				return (
					<div className="empty-box">
						<div className="d-flex justify-content-center">
							<img src={require('../assets/images/last-income.png')} />
						</div>
						<p className="fs-12 fw-500 text-center">{state.lang == 'fa' ? 'متاسفانه هنوز سرمایه‌گذاری انجام نشده است.' : 'Unfortunately, the investment has not been made yet.'}</p>
						<div className="d-flex justify-content-center">
							<Link to={'/new-order'} className="fs-10 fw-500">
								{state.lang == 'fa' ? 'شروع سرمایه‌گذاری' : 'Start Investing'}
							</Link>
						</div>
					</div>
				);
			}
		}
	};

	const [authLoading, setAuthLoading] = useState(true); //* loading, success, error
	const navigate = useNavigate();

	const getUser = async () => {
		try {
			let user_data = localStorage.getItem('user');
			if (!state?.user?.access_token || !user_data) {
				// setAuthLoading(false);
				// navigate('/auth/login');
				const current_location = window.location.href;
				if (!current_location.includes('auth')) {
					window.location.replace('/auth/login' + '?redirect=' + current_location + '');
				} else {
					window.location.replace('/auth/login');
				}
				return;
			}
			//* Update User data by each reload page or every time visit the panel.
			const { data } = await Api.get('/user/profile');

			user_data = JSON.parse(user_data);
			user_data = { ...user_data, user: data?.data };
			dispatch('user', user_data);
			// localStorage.setItem('user', JSON.stringify(user_data));
			setAuthLoading(false);
		} catch (e) {
			console.log('E', e);
			localStorage.removeItem('user');
			const current_location = window.location.href;
			if (!current_location.includes('auth')) {
				// window.location.replace('/auth/login' + '?redirect=' + current_location + '');
			} else {
				// window.location.replace('/auth/login');
			}

			// navigate('/auth/login');
			// setAuthLoading(false);
		} finally {
			//
		}
	};

	useEffect(() => {
		getUser();
	}, []);

	
	
	return (
		<>
			{authLoading === true ? (
				<Modal show centered>
					<Modal.Body>
						<div className="d-flex align-items-center justify-content-center flex-column">
							<img src={require('../assets/icons/RANGI.png')} alt="logo" style={{ width: 120, height: 120 }} />
							{/* <img src={require('../assets/')} alt="logo" style={{ width: 120, height: 120 }} /> */}

							<div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
								<Spinner size="sm" />
								<p>Please wait...</p>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			) : (
				<div id="mainLayout" className={`main ${show ? 'drawer-opened' : ''}`}>
					<div className="main-layout-wrapper">
						<div className="d-flex drawer p-0">
							<div className="logo">
								<Link to={'/'}>
									<img src={require('../assets/images/light-full-logo2.png')} alt='logo' />
								</Link>
							</div>
							<div className="links">
								<ul>
									{links.map((link) => {
										return (
											<li className={`fs-14 fw-500 ${pathname == link.to ? 'link-active' : ''}  `}>
												<Link to={link.to}>
													<span className="drawer-image-icon">{link.img}</span>
													<span>{state.lang == 'fa' ? link.label : link.en.label}</span>
												</Link>
											</li>
										);
									})}
								</ul>
							</div>
							<div className="bottom-wrapper">
								{/* <SupportCard loading={loading} data={data} /> */}
								<div className="exit" style={{ cursor: 'pointer' }}>
									<button className="fs-14 fw-500" onClick={() => logout()} disabled={state.loading}>
										<img src={require('../assets/images/exit.png')} />
										{state.loading ? (
											<div className="d-flex justify-content-center ">
												<Spinner size="md" className="mx-2" />
											</div>
										) : (
											<>{state.lang == 'fa' ? <span>خروج از حساب</span> : <span>Log out</span>}</>
										)}
									</button>
								</div>
							</div>
						</div>
						<div className="content">
							<div className="container">
								<div className="navbar-panel d-flex align-items-center justify-content-between">
									<div className="d-flex align-items-center">
										<button className="buger-btn" onClick={toggle}>
											<HambergerMenu />
										</button>
										<h1 className="fs-20 fw-600 m-0 ">{render()}</h1>
									</div>
									<div className="show-date">
										{/* <p className="fs-14 fw-500">{state.lang == 'fa' ? now() : nowEn()}</p> */}
										{/* <img src={require('../assets/images/date.png')} /> */}
									</div>
								</div>
								{children}
							</div>
						</div>
					</div>

					<div onClick={() => setShow(false)} className={`drawer-layout`}></div>

					<div className="row">
						{/* <div className="col-lg-10 col-12 main-content" data-background={pathname == '/about-us'} style={{}}></div> */}
						{/* <div className="col-lg-2 d-none d-lg-block last-incomes">
							<div className="">
								<h3 className="fs-16 fs-600">Last Investments</h3>
								{renderLastOrder()}
								<Income type="progress" />
								<Income type="success" />
								<Income type="reject" />
								<div className="select-lang-wrapper">
									<SelectLanguage />
								</div>
							</div>
						</div> */}
					</div>

					{/* <Offcanvas placement={state.lang == 'fa' ? 'end' : 'start'} show={show} onHide={toggle}>
						<Offcanvas.Header closeButton>
							<Offcanvas.Title>
								<img style={{ height: 120, width: 'auto' }} src={require('../assets/images/full-logo.png')} />
							</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body className="d-flex flex-column">
							<div className="links">
								<ul>
									{links.map((link) => {
										return (
											<li className={`fs-14 fw-500 ${pathname == link.to ? 'link-active' : ''}  `}>
												<Link to={link.to}>
													{link.img}
													<span>{link.label}</span>
												</Link>
											</li>
										);
									})}
								</ul>
							</div>
							<div className="bottom-wrapper">
								<SupportCard loading={loading} data={data} />
								<div className="exit">
									<button className="fs-14 fw-500" onClick={() => logout()} disabled={state.loading}>
										<img src={require('../assets/images/exit.png')} />
										{state.loading ? (
											<div className="d-flex justify-content-center ">
												<Spinner size="md" className="mx-2" />
											</div>
										) : (
											<>{state.lang == 'fa' ? <span>خروج از حساب</span> : <span>Log out</span>}</>
										)}
									</button>
								</div>
							</div>
						</Offcanvas.Body>
					</Offcanvas> */}
					{/* <Offcanvas
						placement={state.lang == 'fa' ? 'start' : 'end'}
						show={left}
						onHide={() => {
							setLeft(!left);
						}}
					>
						<Offcanvas.Body>
							<div className="">
								<h3 className="fs-16 fs-600">Last Investments</h3>
								{renderLastOrder()}
							</div>
						</Offcanvas.Body>
					</Offcanvas> */}
					{/* <button
						className={`open-left-side d-block d-lg-none ${left && 'move-right'}`}
						onClick={() => {
							setLeft(!left);
						}}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
							<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
						</svg>
	
					</button> */}

					<div className="bottom-navigator">
						<Link to={"/wallet"} className={`bottom-navigator--item ${pathname === '/wallet' ? 'bottom-navigator-item-active' : '' }`}  >
							<span>Wallet</span>
							<Wallet variant={pathname === '/wallet' ? 'Bold' : 'Linear'} />
						</Link>
						<Link to={"/referral"} className={`bottom-navigator--item ${pathname === '/referral' ? 'bottom-navigator-item-active' : '' }`}>
							<span>Referral</span>
							<UserAdd  variant={pathname === '/referral' ? 'Bold' : 'Linear'} />
						</Link>
						<Link to={"/"} className={`bottom-navigator--item ${pathname === '/' ? 'bottom-navigator-item-active' : '' }`}>
							<span>Home</span>
							<Home variant={pathname === '/' ? 'Bold' : 'Linear'} />
						</Link>
						<Link to={"/new-order"} className={`bottom-navigator--item ${pathname === '/new-order' ? 'bottom-navigator-item-active' : '' }`}>
							<span>New Invest</span>
							<Money variant={pathname === '/new-order' ? 'Bold' : 'Linear'} />
						</Link>
						<Link to={"/tickets"} className={`bottom-navigator--item ${pathname === '/tickets' ? 'bottom-navigator-item-active' : '' }`}>
							<span>Support</span>
							<I24Support variant={pathname === '/tickets' ? 'Bold' : 'Linear'} />
						</Link>
					</div>
				</div>
			)}
		</>
	);
};

export default Layout;
