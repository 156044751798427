import { toast } from 'react-toastify';
import Button from '../../Components/button';
import { useReferral } from './hook';
import { EmptyWallet } from 'iconsax-react';

const ReferralPage = () => {
	const { fetchLoading, generateReferralCodeHandler, referralCode, referrals, user, customReferral, setCustomReferral, generateHandler } = useReferral();

	console.log('user', user?.user?.referral_code);
	return (
		<div>
			<div className="col-12">
				<div className="personal-box">
					<h2 style={{ fontSize: 15 }}>Referral Program Terms</h2>
					<p className="fs-13 text-text-2 mb-4" style={{ lineHeight: 2 }}>
						By inviting individuals to our platform, you can earn a percentage of their transactions. When a user registers using your referral code and completes their first payment, 10% of that payment
						will be credited to your account as a reward. Additionally, the new user who registers with your referral code will receive $5 in their account as a bonus. <br />
					</p>
					<h2 style={{ fontSize: 14 }}>Share your referral code to earn more rewards.</h2>
					<div className="input mb-10 d-flex">
						<label className="fs-12 fw-500 text-text-2">https://hub.cotex.io?ref_code= </label>
						<input type={'text'} value={referralCode || customReferral} onChange={(e) => setCustomReferral(String(e.target.value).trim())} disabled={referralCode} />
						<Button
							loading={generateHandler}
							onClick={() => {
								// Copy, Auto Generate or submit
								if (referralCode) {
									navigator.clipboard.writeText('https://hub.cotex.io?ref_code=' + referralCode);
									toast.dark('Your referral link copied to clipboard');
									return;
								} else {
									generateReferralCodeHandler();
									return;
								}
							}}
						>
							{referralCode ? 'Copy' : customReferral ? 'Generate my code' : 'Auto Generate'}
						</Button>
					</div>
					<p className="fs-10 text-text-2">The code must be at least 5 characters long and contain only a combination of letters and numbers.</p>
				</div>
			</div>

			<div className="col-12">
				<div className="personal-box">
					<h2 style={{ fontSize: 14 }}>Referral and Subordinate Transaction List ({referrals?.data?.length || 0})</h2>
					<div className="table-content">
						<table className="table">
							<thead>
								<tr>
									<th>#</th>
									<th>Type</th>
									<th>Amount</th>
									<th>Date</th>
									<th>Name</th>
								</tr>
							</thead>

							<tbody>
								<>
									{referrals?.data?.map((referral, key) => {
										const d = new Date(referral?.created_at);
										return (
											<tr key={key}>
												<td width={60}>{key + 1}</td>
												<td>{referral?.type}</td>
												<td>
													<span className="d-flex gap-1 flex-wrap">
														<span>{referral?.amount}</span>
														<span>{referral?.symbol}</span>
													</span>
												</td>
												<td>{d?.toLocaleDateString('en-US')}</td>
												<td width={150}>{referral?.sub_user?.fullname}</td>
											</tr>
										);
									})}
								</>
							</tbody>
						</table>
						{referrals?.data?.length === 0 && (
							<div className="d-flex  flex-column align-items-center gap-2 my-5">
								<EmptyWallet size={60} color="var(--text-2)" />
								<p className="text-text-2 fs-13 text-center">You haven't referred anyone yet. Invite friends now and earn rewards.</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReferralPage;
