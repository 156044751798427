import { useMainContext } from '../../../Context';
import { useIdentification } from '../Hooks';
import Button from '../../../Components/button';
import { Trash } from 'iconsax-react';
import ContentLoader from 'react-content-loader';
import Loading from '../../../Components/Loading';

const Identification = () => {
	const {
		state: { user },
	} = useMainContext();
	const { identificationStatus, identificationType, toggleIdentificationBtn, uploadLoading, setFiles, files, loading, validationHandler, error, setError } = useIdentification();
	if (loading) {
		return <Loading />;
	}
	if (identificationStatus) {
		return <></>;
	}
	return (
		<div className="col-12" id="identification-page">
			<div className="personal-box">
				<div>
					<p className="fs-12 fw-500 mt-1">To complete the identity verification process, please choose one of the following methods:</p>

					<ul className="fs-12 fw-500 mt-3 mb-2 conditions-list">
						<li>Uploading the front and back images of your ID Card.</li>
						<li>Submitting a photo of yourself holding a piece of paper with a specified text written on it.</li>
					</ul>
					<p className="fs-12 fw-500 mt-1">Choosing one of these methods is required to complete your verification.</p>
				</div>

				<div className="d-flex align-items-center justify-content-center identification">
					<div className="toggle-component">
						<button onClick={toggleIdentificationBtn} className={identificationType === 'IDCard' && 'active'}>
							ID Card
						</button>
						<button onClick={toggleIdentificationBtn} className={identificationType === 'Photo' && 'active'}>
							Taking Pictures
						</button>
					</div>
				</div>

				{identificationType === 'IDCard' ? (
					<>
						<div>
							<p className="fs-12 fw-500 mt-3" style={{ lineHeight: 1.9 }}>
								Please upload both the front and back images of your ID Card to complete the identity verification process. Kindly ensure that:
							</p>
							<ul className="fs-12 fw-500 mt-3 mb-2 conditions-list">
								<li>All information on the ID Card is clear and legible.</li>
								<li>Do not use edited or outdated images.</li>
								<li>If the uploaded image is of insufficient quality, your verification process may be delayed.</li>
							</ul>
						</div>

						<div className="identification-uploader mt-4">
							<label>
								<span>The Front of ID Card</span>
								<input
									type="file"
									accept="image/*"
									onChange={(e) => {
										const file = e.target.files[0];
										if (file) {
											setFiles({ ...files, front: file });
										}
										e.target.files = null;
										setError({ ...error, front: false });
									}}
								/>
							</label>
							{error.front && <p className="form-error">The Front of ID Card is required!</p>}
							{files.front && (
								<div className="upload-item">
									<img alt="" src={URL.createObjectURL(files.front)} />
									<p>{files.front.name}</p>
									<Trash
										onClick={() => {
											setFiles({ ...files, front: null });
										}}
										size={'16px'}
									/>
								</div>
							)}

							<label className="">
								<span>The Back of ID Card</span>
								<input
									type="file"
									accept="image/*"
									onChange={(e) => {
										const file = e.target.files[0];
										if (file) {
											setFiles({ ...files, back: file });
										}
										e.target.files = null;
										setError({ ...error, back: false });
									}}
								/>
							</label>
							{error.back && <p className="form-error">The Back of ID Card is required!</p>}

							{files.back && (
								<div className="upload-item">
									<img alt="" src={URL.createObjectURL(files.back)} />
									<p>{files.back.name}</p>
									<Trash
										size={'16px'}
										onClick={() => {
											setFiles({ ...files, back: null });
										}}
									/>
								</div>
							)}
						</div>
					</>
				) : (
					<>
						<p className="fs-12 fw-500 mt-3 mb-2" style={{ lineHeight: 1.9 }}>
							To complete the identity verification process and confirm your information, please upload a photo of yourself holding a piece of paper with the following text written on it. The paper must be
							clearly visible and held in your hand without any edits or alterations. This step ensures the security and accuracy of the information provided by our valued users. Please note:
						</p>
						<ul className="fs-12 fw-500 mt-3 mb-2 conditions-list" style={{ lineHeight: 1.9 }}>
							<li>Your face and the text on the paper must be clearly visible in the image.</li>
							<li>Do not use old or edited photos.</li>
							<li>The information on the paper must match the information provided in your account.</li>
						</ul>
						<p className="fs-12 fw-500 mt-3 mb-2">Please write the following text by hand on the paper and include it in the photo:</p>
						<p className="fs-14 fw-600 mt-3 mb-2" style={{ lineHeight: 1.9 }}>
							"I, <span className="blank-word">[Full Name]</span> , with National ID Number <span className="blank-word"> [National ID/Identification Number]</span>, confirm that this image belongs to me and
							that all the information provided on the Cotex website is accurate and belongs to me. I also declare that I have carefully read and agree to all the terms and conditions of the Cotex website."
						</p>

						<div className="identification-uploader mt-4">
							<label>
								<span>Identification Photo</span>
								<input
									type="file"
									accept="image/*"
									onChange={(e) => {
										const file = e.target.files[0];
										if (file) {
											setFiles({ ...files, photo: file });
										}
										e.target.files = null;
										setError({ ...error, photo: false });
									}}
								/>
							</label>
							{error.photo && <p className="form-error">Identification Photo is required!</p>}

							{files.photo && (
								<div className="upload-item">
									<img alt="" src={URL.createObjectURL(files.photo)} />
									<p>{files.photo.name}</p>
									<Trash
										onClick={() => {
											setFiles({ ...files, photo: null });
										}}
										size={'16px'}
									/>
								</div>
							)}
						</div>
					</>
				)}

				<div className="mt-3  " onClick={validationHandler}>
					<Button loading={uploadLoading}>Upload</Button>
				</div>
			</div>
		</div>
	);
};

export default Identification;
