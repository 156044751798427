import React from 'react';
import { useMainContext } from '../../../Context';

const HeroCard = () => {
	const {
		state: { user, lang },
	} = useMainContext();
	return (
		<div className="hero-card">
			<div>
				<p className="welcome fs-22 fw-500 text-text-1">
					Dear <span className="fw-700 text-text-1">{user?.user?.fullname || "User"}</span> 
					<br /> welcome to your account.
				</p>
				<p className="fs-12 fw-500 text-text-1 ">
					You can use this website to invest and manage
					<br />
					your investment.
				</p>
			</div>
		</div>
	);
};

export default HeroCard;
