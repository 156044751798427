import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFound = () => {
	return (
		<div className="text-center mt-4">
			<h1 style={{ fontSize: '3.5rem' }}>Oops!</h1>

			<p style={{ fontSize: '1.5rem' }}>404 - PAGE NOT FOUND</p>
			<p className="mt-2" style={{ fontSize: '1rem', lineHeight: '1.8' }}>
				The page you are looking for might have been removed
				<br />
				had it's name changed or is temporarily unavailable.
			</p>
			<div>
				<Link style={{ background: '#4c72ac' }} className="btn  text-white mt-4" to={'/'}>
					Go back home
				</Link>
			</div>
		</div>
	);
};

export default NotFound;
