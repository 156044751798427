import { useEffect, useState } from "react";
import { useMainContext } from "../../Context";
import { maindata } from "../../Utils/Apis";

export const useMainData = () => {
  const { post } = useMainContext();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const getMainData = async () => {
    setLoading(true);
    // const { data, status } = await post(maindata);
    // if (+status == 200) {
    //   setData(data);
    // }
    setLoading(false);
  };
  useEffect(() => {
    getMainData();
  }, []);

  return { data, loading };
};
