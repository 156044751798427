import { useEffect, useState } from 'react';
import { useMainContext } from '../../../Context';
import { newOrder, packageList } from '../../../Utils/Apis';
import { useNavigate } from 'react-router-dom';

export const useOrder = () => {
	const {
		post,
		state: { plans },
	} = useMainContext();
	const [form, setForm] = useState({
		vip: true,
		selected: null,
		check: false,
	});
	const [loading, setLoading] = useState(true);
	const [loadingBtn, setLoadingBtn] = useState({ rial: false, coin: false });
	const [data, setData] = useState([]);
	const [error, setError] = useState({ status: false, key: [] });
	const [paymentType, setPaymentType] = useState('online');
	const navigate = useNavigate();

	const togglePaymentType = () => {
		if (paymentType === 'online') setPaymentType('wallet');
		else setPaymentType('online');
	};

	const getData = async () => {
		setLoading(true);
		const { data, status } = await post(packageList);
		if (+status === 200) {
			setData(data);
		}
		setLoading(false);
	};
	useEffect(() => {
		getData();
	}, []);

	const check = (key) => {
		if (error.status) {
			return form[key] ? true : false;
		}
		return true;
	};
	const validate = () => {
		if (form.vip) {
			if (form.selected && form.check) {
				return { status: true };
			} else {
				return {
					keys: check(),
					status: false,
				};
			}
		} else {
			return { status: !!form.price };
		}
	};
	const submit = () => {
		if (validate()['status']) {
			setForm({ ...form, ready: true });
		} else {
			setError({ status: true });
		}
	};
	const calcualte = () => {
		let res = { interest_rates: '-', period: '-' };
		if (form.price) {
			data.map((plan, count) => {
				if (+data[data.length - 1].min_amount < +form.price) {
					res = data[data.length - 1];
				} else if (+form.price == plan.min_amount) {
					res = plan;
				} else {
					if (+form.price > +plan.min_amount && +form.price < +data[count + 1].min_amount) {
						res = plan;
					}
				}
			});
		} else {
			if (form.selected) {
				res = data.filter((item) => item.id == form.selected)[0];
			}
		}
		return res;
	};
	const submitOrder = async (peytype) => {
		setLoadingBtn({ ...loadingBtn, [peytype]: true });
		let obj = { type: '', package_id: form.selected, typepay: peytype, coin_id: 2 };
		if (form.vip) {
			obj.type = paymentType;
		} else {
			obj.type = 'desired';
			obj.amount = form.price;
			obj.package_id = calcualte().id;
			obj.type = paymentType;
		}
		const resp = await post(newOrder, obj, true);
		if (+resp.status == 200) {
			console.log('DATA', resp?.response?.data?.invoice_url);
			if (paymentType === 'wallet') {
				navigate('/orders');
			} else {
				window.location.replace(resp?.response?.data?.invoice_url);
			}
		}
		setLoadingBtn({ ...loadingBtn, [peytype]: false });
	};
	const modalToggler = () => setForm({ ...form, ready: !form.ready });
	const toggle = () => {
		setForm({ vip: !form.vip });
		setError({ status: false });
	};
	return {
		form,
		setForm,
		toggle,
		submit,
		check,
		calcualte,
		modalToggler,
		loading,
		data,
		submitOrder,
		loadingBtn,
		paymentType,
		togglePaymentType,
	};
};
