import React from 'react';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeroCard from '../../Components/Cards/Hero';
import LineChart from '../../Components/Chart';
import { useDashboard } from './Hook';
import Loading from '../../Components/Loading';
import './styles.scss';

const Dashboard = () => {
	const { data, loading, changeObj, getValue } = useDashboard();

	if (loading) {
		return (
			<>
				<Loading />
				<Loading />
				<Loading />
			</>
		);
	}

	

	return (
		<>
			<HeroCard />
			{/* {data?.has_identification === false ? (
				<div className="mt-3">
					<Alert variant="danger">
						<div className="d-flex align-center justify-content-between alert-dashboard">
							<p className="text-danger fs-12">You need to complete your identification</p>
							<Link className="text-danger fw-500 fs-12" to={'/profile'}>
								Verify now
							</Link>
						</div>
					</Alert>
				</div>
			) : (
				<></>
			)} */}

			{data.chart?.length > 0 && (
				<>
					<div className="dashboard-chart-cards mt-4">
						<div className="personal-box" style={{ marginBottom: 10 }}>
							<h3>Profit per day</h3>
							<div className="d-flex align-items-center gap-2">
								<p> &#8776; {data?.profit_per_day ? data?.profit_per_day.toFixed(3) : '0.000'}</p>
								<span>USD</span>
							</div>
						</div>
						<div className="personal-box" style={{ marginBottom: 10 }}>
							<h3>Profit per month</h3>
							<div className="d-flex align-items-center gap-2">
								<p>&#8776; {data?.profit_per_month ? data?.profit_per_month.toFixed(3) : '0.000'}</p>
								<span>USD</span>
							</div>
						</div>

						<div className="personal-box" style={{ marginBottom: 10 }}>
							<h3>Total investment</h3>
							<div className="d-flex align-items-center gap-2">
								<p> {data?.total_investment || 0}</p>
								<span>USD</span>
							</div>
						</div>
					</div>
				</>
			)}


			<LineChart changeObj={changeObj} getValue={getValue} dataSets={data.chart ? data.chart.reverse() : []} />
		
		</>
	);
};

export default Dashboard;
