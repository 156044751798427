import { Card, Modal, Pagination } from 'react-bootstrap';
import Button from '../../../Components/button';
import { useTicket } from './useTicket';
import './styles.scss';
import { Link } from 'react-router-dom';
import Loading from '../../../Components/Loading';
import moment from 'jalali-moment';

const TicketPage = () => {
	const { tickets, addTicketModal, setAddTicketModal, onSubmit, handleSubmit, register, fetchTicketsLoading, errors, postTicketLoading, reset, handlePageClick } = useTicket();
	if (fetchTicketsLoading) {
		return <Loading />;
	}
	return (
		<div id="tickets" className='personal-box'>
			<div className="mb-2">
				<button className='btn-fill px-3 py-1 fs-13 fw-600' onClick={() => setAddTicketModal(true)}>New Ticket</button>
			</div>

			<Modal
				centered
				show={addTicketModal}
				onHide={() => {
					setAddTicketModal(false);
					reset();
				}}
			>
				<Modal.Body>
					<div className="fw-700 mb-3 ms-1 fs-18">New Ticket</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="input mb-10">
							<label className="fs-12 fw-500 ml-3">Subject: </label>
							<input type={'text'} {...register('subject')} />
						</div>
						{errors.subject && <small className="error-message mb-5 block">{errors.subject.message}</small>}
						<div className="input-textarea mb-10">
							<label className="fs-12 fw-500">Content: </label>
							<textarea rows={5} type={'text'} {...register('content')} />
						</div>{' '}
						{errors.content && <small className="error-message mb-5 block">{errors.content.message}</small>}
						<div>
							<Button loading={postTicketLoading} type="submit" size="md" className="px-4 py-2 btn-fill">
								Submit
							</Button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			<div style={{ borderRadius: 6 }}>
				<div className="table-content">
					<table className="table">
						<thead>
							<tr>
								<th>#</th>
								<th>Subject</th>
								<th>Date</th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							{tickets.data.map((item, key) => (
								<tr key={key}>
									<td width={60}>{key + 1}</td>
									<td>{item.subject}</td>
									<td width={150}>{moment(item.created_at).format('YYYY/MM/DD')}</td>
									<td width={150}>
										<Link to={`/tickets/${item.id}`}>Show more</Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				{/* <Pagination /> */}
			</div>
		</div>
	);
};

export default TicketPage;
