import React, { useState } from 'react';
import './index.scss';
import { ReactComponent as Icon } from '../../assets/images/clock.svg';
import { ReactComponent as Kol } from '../../assets/images/kol.svg';
import { ReactComponent as Sod } from '../../assets/images/sod.svg';
import { ReactComponent as Details } from '../../assets/images/details.svg';
import { ReactComponent as RequestActive } from '../../assets/images/request-active.svg';
import { ReactComponent as RequestDeactive } from '../../assets/images/request-deactive.svg';
import DashboardCard from '../../Components/Cards/Dashboard';
import { Link } from 'react-router-dom';
import { Accordion, Modal, Spinner } from 'react-bootstrap';
import { useOrder, useRequest } from './Hooks';
import Loading from '../../Components/Loading';
import { p2e, renderPrice, ToDate, ToDateJalali } from '../../Utils/functions';
import { useCounter } from '../../Hooks';
import { toast } from 'react-toastify';
import { useMainContext } from '../../Context';

const Orders = () => {
	const { data, loading, form, render, setForm, renderDetails } = useOrder();
	const props = {
		className: 'icon-hover',
	};

	console.log('order data', data?.orders);
	return (
		<div>
			{loading ? (
				<div className="row">
					<Loading count={3} />
				</div>
			) : (
				<>
					{/* <div className="dashboard-card-wrapper">
						{[
							{
								title: 'invest amount',
								subtitle: data.total_invest + 'USD',
								icon: <Icon {...props} />,
								active: true,
							},
						].map((item) => (
							<DashboardCard title={item.title} subtitle={item.subtitle} icon={item.icon} active={item.active} loading={loading} />
						))}
					</div> */}
					<div className="personal-box mt-2">
						<div className="table-wrapper ">
							<div className="table-header">
								<p className="fs-12 fw-500 text-text-2">The capital cannot be withdrawn until the end of the investment period, but you can withdraw the profit from the investment.</p>
								<p className="fs-12 fw-500 text-text-2">
									Your withdrawal request will be deposited to the wallet registered in your <Link to={'/profile'}> account </Link> settings after payment.
								</p>
							</div>

							{data?.orders?.data?.length > 0 ? (
								<table className="table">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Date Of Request</th>
											<th scope="col">Type Of Request</th>
											<th scope="col">Amount (USD)</th>
											<th scope="col">Status</th>
										</tr>
									</thead>
									<tbody>
										{data?.orders?.data?.map((item, i) => {
											const { status, text, access } = renderDetails(item);
											const d = new Date(item?.created_at);
											return (
												<tr>
													<th scope="row">{i + 1}</th>
													<td>{d?.toLocaleDateString('en-US')}</td>
													<td>Buy Package</td>
													<td>{renderPrice(item.amount_in_usd, ',')}</td>
													<td>
														<span className="badge-status" data-status={'success'} ml={'15px'}></span>
														{item?.status}
														{/* {text} */}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							) : (
								<>
									<div className="empty mt-4">
										{/* <img src={require('../../assets/images/empty.jpg')} alt="empty" /> */}
										<p className="fs-12 fw-500 text-center sad">Unfortunately, the investment has not been made yet.x</p>
										<div className="d-flex justify-content-center">
											<Link to={'/new-order'} className="text-center fs-14 ">
												Start Investing
											</Link>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Orders;

// const renderEmpty = () => {
// 	// console.log("DATA" , data?.orders)
// 	if (data?.orders?.data && data?.orders?.data?.length > 0) {
// 		if (window.innerWidth < 720) {
// 			return (
// 				<Accordion className="order-accordion" defaultActiveKey={['0']} alwaysOpen>
// 					<div className="orders-mobile">
// 						{/* {data?.orders?.data?.map((item, key) => {
// 							return <RowMobile item={item} render={render} form={form} setForm={setForm} data={data} key={key} id={key} />;
// 						})} */}
// 					</div>
// 				</Accordion>
// 			);
// 		}
// 		return (
// 			<div className="table-content">
// 				<table className="table">
// 					<thead>
// 						<tr>
// 							<th scope="col">{lang == 'fa' ? 'شناسه' : 'ID'}</th>
// 							<th scope="col">{lang == 'fa' ? 'تاریخ شروع' : 'Start Date'}</th>
// 							<th scope="col">{lang == 'fa' ? 'تاریخ پایان' : 'End Date'}</th>
// 							<th scope="col">{lang == 'fa' ? 'مبلغ سرمایه‌گذاری' : 'Investment Amount'}</th>
// 							<th scope="col">{lang == 'fa' ? 'قابل برداشت' : 'Withdrawable'}</th>
// 							<th scope="col">{lang == 'fa' ? 'وضعیت' : 'Status'}</th>
// 							<th scope="col">{lang == 'fa' ? 'نوع پرداخت' : 'Payment Type'}</th>
// 							<th scope="col text-center fix-col" data-center={true}>
// 								{lang == 'fa' ? 'جزئیات' : 'Details'}
// 							</th>
// 						</tr>
// 					</thead>
// 					<tbody>
// 						{data?.orders?.data?.map((item, i) => {
// 							return <Row key={i} render={render} item={item} form={form} data={data} setForm={setForm} />;
// 						})}
// 					</tbody>
// 				</table>
// 			</div>
// 		);
// 	} else {
// 		return (

// 		);
// 	}
// };

// const Orders = () => {
// 	const { data, loading, form, render, setForm, renderDetails } = useOrder();
// 	const {
// 		state: { lang },
// 	} = useMainContext();
// 	console.log({ data });
// 	const props = {
// 		className: 'icon-hover',
// 	};
// 	const [workingـcapital] = useCounter({
// 		plus: true,
// 		start: data?.workingـcapital?.amount,
// 		period: data?.workingـcapital?.steper,
// 	});
// 	const [withdrawable_capital] = useCounter({
// 		plus: true,
// 		start: data?.withdrawable_capital?.amount,
// 		period: data?.withdrawable_capital?.steper,
// 	});
// 	const renderEmpty = () => {
// 		// console.log("DATA" , data?.orders)
// 		if (data?.orders?.data && data?.orders?.data?.length > 0) {
// 			if (window.innerWidth < 720) {
// 				return (
// 					<Accordion className="order-accordion" defaultActiveKey={['0']} alwaysOpen>
// 						<div className="orders-mobile">
// 							{/* {data?.orders?.data?.map((item, key) => {
// 								return <RowMobile item={item} render={render} form={form} setForm={setForm} data={data} key={key} id={key} />;
// 							})} */}
// 						</div>
// 					</Accordion>
// 				);
// 			}
// 			return (
// 				<div className="table-content">
// 					<table className="table">
// 						<thead>
// 							<tr>
// 								<th scope="col">{lang == 'fa' ? 'شناسه' : 'ID'}</th>
// 								<th scope="col">{lang == 'fa' ? 'تاریخ شروع' : 'Start Date'}</th>
// 								<th scope="col">{lang == 'fa' ? 'تاریخ پایان' : 'End Date'}</th>
// 								<th scope="col">{lang == 'fa' ? 'مبلغ سرمایه‌گذاری' : 'Investment Amount'}</th>
// 								<th scope="col">{lang == 'fa' ? 'قابل برداشت' : 'Withdrawable'}</th>
// 								<th scope="col">{lang == 'fa' ? 'وضعیت' : 'Status'}</th>
// 								<th scope="col">{lang == 'fa' ? 'نوع پرداخت' : 'Payment Type'}</th>
// 								<th scope="col text-center fix-col" data-center={true}>
// 									{lang == 'fa' ? 'جزئیات' : 'Details'}
// 								</th>
// 							</tr>
// 						</thead>
// 						<tbody>
// 							{data?.orders?.data?.map((item, i) => {
// 								return <Row key={i} render={render} item={item} form={form} data={data} setForm={setForm} />;
// 							})}
// 						</tbody>
// 					</table>
// 				</div>
// 			);
// 		} else {
// 			return (
// 				<div className="empty">
// 					<img src={require('../../assets/images/empty.jpg')} />
// 					<p className="fs-12 fw-500 text-center sad">{lang == 'fa' ? 'متاسفانه هنوز سرمایه‌گذاری انجام نشده است.' : 'Unfortunately, the investment has not been made yet.x'}</p>
// 					<div className="d-flex justify-content-center">
// 						<Link to={'/new-order'} className="text-center fs-14 new">
// 							{lang == 'fa' ? 'شروع سرمایه گذاری' : 'Start Investing'}
// 						</Link>
// 					</div>
// 				</div>
// 			);
// 		}
// 	};
// 	return (
// 		<div id="orders">
// 			{loading ? (
// 				<div className="row">
// 					<Loading count={3} />
// 				</div>
// 			) : (
// 				<div className="dashboard-card-wrapper">
// 					{[
// 						{
// 							title: 'invest amount',
// 							subtitle: data.total_invest + (lang == 'fa' ? ' دلار' : ' USD'),
// 							icon: <Icon {...props} />,
// 							active: true,
// 						},
// 					].map((item) => (
// 						<DashboardCard title={item.title} subtitle={item.subtitle} icon={item.icon} active={item.active} loading={loading} />
// 					))}
// 				</div>
// 			)}

// 			<div className="table-wrapper">
// 				<div className="table-header">
// 					<p className="fs-12 fw-500">The capital cannot be withdrawn until the end of the investment period, but you can withdraw the profit from the investment.</p>
// 					<p className="fs-12 fw-500">
// 						Your withdrawal request will be deposited to the wallet registered in your <Link to={'/profile'}> account </Link> settings after payment.
// 					</p>
// 				</div>

// 				{loading ? <Loading height={'500px'} /> : renderEmpty()}
// 			</div>

// 			<Modal
// 				show={form['details']}
// 				backdrop="static"
// 				keyboard={false}
// 				centered
// 				onHide={() => {
// 					setForm({ ...form, details: false });
// 				}}
// 			>
// 				<div className="modal-padding">
// 					<div className="modal-title">
// 						<h4 className="fs-14 fw-600">Investment Details</h4>

// 						<button
// 							onClick={() => {
// 								setForm({ ...form, details: false });
// 							}}
// 						>
// 							<img src={require('../../assets/images/close.png')} alt="close" />
// 						</button>
// 					</div>
// 					<div className="table-content mt-12">
// 						<table className="table">
// 							<thead>
// 								<tr>
// 									<th scope="col">ID</th>
// 									<th scope="col">Date Of Request</th>
// 									<th scope="col">Type Of Request</th>
// 									<th scope="col">Amount</th>
// 									<th scope="col">Status</th>
// 								</tr>
// 							</thead>
// 							<tbody>
// 								{form?.item?.details?.map((item) => {
// 									const { status, text, access } = renderDetails(item);

// 									return (
// 										<tr>
// 											<th scope="row">{item.code}</th>
// 											<td>{ToDateJalali(item.created_at)}</td>
// 											{lang == 'fa' ? (
// 												<>
// 													<td>{item.type == 'in' ? 'واریز' : 'برداشت'}</td>
// 												</>
// 											) : (
// 												<>
// 													<td>{item.type == 'in' ? 'deposit' : 'harvest'}</td>
// 												</>
// 											)}
// 											<td>{renderPrice(item.amount, ',')}</td>
// 											<td>
// 												<span className="badge-status" data-status={status} ml={'15px'}></span>
// 												{text}
// 											</td>
// 										</tr>
// 									);
// 								})}
// 							</tbody>
// 						</table>
// 					</div>
// 				</div>
// 			</Modal>
// 			<Request form={form} setForm={setForm} />
// 		</div>
// 	);
// };

// export default Orders;
// const Row = ({ render, form, setForm, item, data }) => {
// 	const { status, text, access } = render(item);
// 	const {
// 		state: { lang },
// 	} = useMainContext();
// 	console.log('i', item);
// 	const [steper] = useCounter({
// 		plus: true,
// 		start: item?.withdrawable,
// 		period: item?.steper,
// 	});
// 	console.log('step =?????????????????????????????????????', steper);
// 	return (
// 		<tr key={item.tracking_code}>
// 			<th scope="row">{item.tracking_code}</th>
// 			<td>{item.start_date ? (lang == 'fa' ? ToDateJalali(item.start_date) : ToDate(item.start_date)) : '-----'}</td>
// 			<td>{item.start_date ? (lang == 'fa' ? ToDateJalali(item.exp_date) : ToDate(item.start_date)) : '-----'}</td>
// 			<td>{renderPrice(item.dolar_amount)}</td>
// 			<td>{item.withdrawable ? renderPrice(steper?.toFixed(2)) : '-----'}</td>
// 			<td>
// 				<span className="badge-status" data-status={status} ml={'15px'}></span>
// 				{text}
// 			</td>
// 			<td>{item.type_pey == 'rial' ? (lang == 'fa' ? 'ریال' : '﷼') : lang == 'fa' ? 'دلار' : 'USD'}</td>
// 			<td data-center={true}>
// 				<div className="action">
// 					<button
// 						disabled={!access.includes('details')}
// 						onClick={() => {
// 							setForm({
// 								...form,
// 								details: !form.details,
// 								item,
// 							});
// 						}}
// 					>
// 						<Details data-status={!access.includes('details')} />
// 					</button>
// 					<button
// 						onClick={() => {
// 							if (+steper > +data.min_withdrawable) {
// 								setForm({
// 									...form,
// 									request: !form.request,
// 									item,
// 								});
// 							} else {
// 								toast.error(lang == 'fa' ? `حداقل میزان برداشت سود${data.min_withdrawable} دلار می باشد` : `The minimum withdrawal amount is $${data.min_withdrawable}`);
// 							}
// 						}}
// 						disabled={!access.includes('request')}
// 					>
// 						<RequestActive data-status={!access.includes('request')} />
// 					</button>
// 				</div>
// 			</td>
// 		</tr>
// 	);
// };
// const Request = ({ form, setForm }) => {
// 	const [steper] = useCounter({
// 		plus: true,
// 		start: form?.item?.withdrawable,
// 		period: form?.item?.steper,
// 	});
// 	const { loading, sendRequest } = useRequest({ setForm, form });
// 	const {
// 		state: { lang },
// 	} = useMainContext();
// 	return (
// 		<Modal
// 			className=""
// 			show={form['request']}
// 			backdrop="static"
// 			keyboard={false}
// 			centered
// 			contentClassName=" mx-356"
// 			onHide={() => {
// 				setForm({ ...form, request: false });
// 			}}
// 		>
// 			<div className="modal-padding ">
// 				<div className="modal-title">
// 					{lang == 'fa' ? <h4 className="fs-14 fw-600">ثبت درخواست برداشت</h4> : <h4 className="fs-14 fw-600">Register withdrawal request</h4>}

// 					<button
// 						onClick={() => {
// 							setForm({ ...form, request: false });
// 						}}
// 					>
// 						<img src={require('../../assets/images/close.png')} />
// 					</button>
// 				</div>
// 				<div className="withdraw">
// 					<div className="key-value mb-5">
// 						{lang == 'fa' ? (
// 							<>
// 								<span className="fs-12 fw-600">مبلغ سرمایه‌گذاری</span>
// 							</>
// 						) : (
// 							<span className="fs-12 fw-600"> Investment Amount</span>
// 						)}
// 						<span className="fs-12 fw-600">{form?.item?.dolar_amount}$</span>
// 					</div>
// 					<div className="key-value">
// 						{lang == 'fa' ? <span className="fs-12 fw-600">مبلغ قابل برداشت</span> : <span className="fs-12 fw-600">Withdrawable Amount</span>}

// 						<span className="fs-12 fw-600">{steper?.toFixed(2)}$</span>
// 					</div>
// 					<div className="hr" />
// 					<p className="fs-12 fw-600">{lang == 'fa' ? 'میزان سرمایه مورد نظر برای برداشت را وارد و ثبت‌ نمایید.' : 'Enter and register the desired capital amount for withdrawal.'}</p>
// 					<div className="d-flex justify-content-center">
// 						<div className="price-input" data-status={{}}>
// 							<span>$</span>
// 							<input
// 								placeholder="1100"
// 								onChange={({ target: { value } }) => {
// 									setForm({ ...form, amount: p2e(value) });
// 								}}
// 								autoFocus={true}
// 							/>
// 						</div>
// 					</div>

// 					<div className="d-flex justify-content-center">
// 						<button
// 							className="fs-10 fw-500"
// 							onClick={() => {
// 								sendRequest({ ...form, order_id: form.item.id });
// 							}}
// 						>
// 							{loading ? <Spinner size="sm" /> : lang == 'fa' ? 'ثبت درخواست' : 'Submit Request'}
// 						</button>
// 					</div>
// 				</div>
// 			</div>
// 		</Modal>
// 	);
// };
// const RowMobile = ({ item, form, setForm, render, data, id }) => {
// 	console.log('i', id);
// 	const { status, access } = render(item);
// 	const {
// 		state: { lang },
// 	} = useMainContext();
// 	const [steper] = useCounter({
// 		plus: true,
// 		start: item?.withdrawable,
// 		period: item?.steper,
// 	});
// 	return (
// 		<Accordion.Item eventKey={`${id}`} className="order-mobile">
// 			<Accordion.Header>
// 				<div className="d-flex justify-content-between align-items-center  header-card">
// 					<p className="fs-12">{lang == 'fa' ? 'شناسه' : 'ID'} </p>
// 					<p className="tracking_code">{`#${item.tracking_code}`}</p>
// 				</div>
// 			</Accordion.Header>
// 			<Accordion.Body>
// 				<div className="" data-status={render(item)['status']}>
// 					<div className="card-content">
// 						<div className="d-flex justify-content-between my-8">
// 							<p className="access">{lang == 'fa' ? 'قابل برداشت' : 'Withdrawable'}</p>
// 							<p className="access-value">{item.withdrawable ? item.withdrawable.toFixed(2) : '-----'}</p>
// 						</div>
// 						<div className="d-flex justify-content-between my-8">
// 							<p className="total">{lang == 'fa' ? 'مبلغ سرمایه‌گذاری' : 'Investment Amount'}</p>
// 							<p className="total-value">{item.dolar_amount.toFixed(2)}</p>
// 						</div>
// 						<div className="d-flex justify-content-between my-8">
// 							<p className="total">{lang == 'fa' ? 'تاریخ شروع و پایان' : 'Start And End Date'}</p>
// 							{item.start_date ? (
// 								<p className="time">{lang == 'fa' ? <>{`${ToDateJalali(item.start_date)}  تـــا  ${ToDateJalali(item.exp_date)}`}</> : <>{`${ToDate(item.start_date)}  to  ${ToDate(item.exp_date)}`}</>}</p>
// 							) : (
// 								'-----'
// 							)}
// 						</div>
// 						<div className="d-flex justify-content-center">
// 							<div className="divider-order"></div>
// 						</div>

// 						<div className="d-flex align-items-center justify-content-between mt-1">
// 							<p className="status-order">
// 								<span className="badge-status" data-status={status} ml={'15px'}></span>
// 								{render(item)['text']}
// 							</p>
// 							<div className="action">
// 								<button
// 									disabled={!access.includes('details')}
// 									onClick={() => {
// 										setForm({
// 											...form,
// 											details: !form.details,
// 											item,
// 										});
// 									}}
// 								>
// 									<Details data-status={!access.includes('details')} />
// 								</button>
// 								<button
// 									onClick={() => {
// 										if (+steper > +data.min_withdrawable) {
// 											setForm({
// 												...form,
// 												request: !form.request,
// 												item,
// 											});
// 										} else {
// 											toast.error(`حداقل میزان برداشت سود${data.min_withdrawable} دلار می باشد`);
// 										}
// 									}}
// 									disabled={!access.includes('request')}
// 								>
// 									<RequestActive data-status={!access.includes('request')} />
// 								</button>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</Accordion.Body>
// 		</Accordion.Item>
// 	);
// };
